<section class="hero hero--small hero-image-background">
    <div class="hero__section-1">
        <div class="hero__title" data-aos="fade-in" data-aos-delay="100" data-aos-easing="ease-out-cubic">
            Contact
        </div>
    </div>
    <section class="contact padding-top-0">
        <div class="contact__content">
            <div class="contact__content__section-1">
                <div class="font-size-20 text-align-center width-max-768" data-aos="fade-in"
                    data-aos-easing="ease-out-cubic">
                    If you’ve got something you would like to share with us, send a message to the following email
                    address:
                </div>
            </div>
            <div class="contact__content__section-2" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <a class="button button--email" href="mailto:info@pro-sharp.hu">info@pro-sharp.hu</a>
            </div>
        </div>
    </section>
</section>

<section class="office">
    <div class="office__content">
        <div class="office__content__section-1">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-anchor-placement="top-bottom">
                <span class="section-title__blue-stripe">Office</span>
            </div>
            <div class="font-size-20 text-align-center width-max-768" data-aos="fade-in"
                data-aos-easing="ease-out-cubic" data-aos-anchor-placement="top-bottom">
                <span class="font-weight-semibold">Pro-Sharp Research and Innovation Center</span><br>
                Bácskai u. 29/A, Budapest 1145 Hungary
            </div>
        </div>
        <div class="office__content__section-2">
            <div class="responsive-map" data-aos="fade-in" data-aos-easing="ease-out-cubic" data-aos-delay="400">
                <iframe class="responsive-map__content"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.588741076451!2d19.103646555357336!3d47.51739989681179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db655b836b9b%3A0xba5a0501997f04f0!2sPro-Sharp%20Hungary!5e0!3m2!1shu!2shu!4v1642692573109!5m2!1shu!2shu"
                    width="768" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
        </div>

    </div>
</section>

<!-- Description of collaborating partners -->
<section class="section">
    <div class="section__content width-max-768">
        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__blue-stripe">Description of collaborating partners</span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Aristotle University of Thessaloniki Department of Physical Education and Sports Science
            </div>
            <div class="padding-top-16 width-full">
                Aristotle University of Thessaloniki - The Department of Physical Education and Sports Science (DPESS)
                of the Aristotle University of Thessaloniki aims to promote an active and healthy lifestyle throughout
                the lifespan through the academic and applied teaching and research. DPESS specializes in the
                social-psychological study of substance use, exercise participation, and lifestyle change across the
                lifespan, as well as the design and application of health promotion and behavior change interventions.
                In addition, DPESS maintains partnership with national and regional public policy and educational
                authorities in order to raise awareness in the general public about the importance of physical activity
                as an essential factor for the enhancement of health and quality of life.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://www.phed.auth.gr/en">https://www.phed.auth.gr/en</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Immanuel Kant Baltic Federal University
            </div>
            <div class="padding-top-16 width-full">
                Immanuel Kant Baltic Federal University – is located in westernmost region in Russia and historically is
                the spirit of Albertina, the University where Kant, Bessel and Jacobi delivered lectures and Schiller
                was a Student. The University aims at increasing and securing competitiveness in the national and global
                markets of university education and R&D through developing competencies in new areas. All these will
                become possible by making breakthrough research in the field of biomedical, neuro-, and nanotechnology
                as well as in social sciences and the humanities. The School of Physical Culture and Sport Sciences
                offers sport science degrees at the bachelor, master and PhD level. Research areas of the School
                situated around students’ physical education aspects, physical activity promotion among various groups,
                elite sport aspects and anti-doping.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://eng.kantiana.ru/">https://eng.kantiana.ru/</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Leipzig University Faculty of Sport Science
            </div>
            <div class="padding-top-16 width-full">
                Leipzig University - The Faculty of Sport Science is one of 14 faculties at Leipzig University. Leipzig
                University was founded in 1409 making it one of the oldest universities in Germany. It is an
                interdisciplinary, international comprehensive university. 35,000 persons research, teach and study at
                the university and more than 4,300 persons are employed at the University Hospital of Leipzig. The
                Faculty of Sport Science offers a total of 10 different sport science degrees at the bachelor, master
                and PhD level. The faculty consists of 14 departments both in natural and human sciences. Research areas
                of the Department of Sport Psychology include doping prevention, psychology of elite sports and mental
                health and well-being.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://www.spowi.uni-leipzig.de/en/sport-psychology">https://www.spowi.uni-leipzig.de/en/sport-psychology</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Kingston University Department of Applied and Human Sciences
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://www.kingston.ac.uk/faculties/science-engineering-and-computing/about/schools/life-sciences-pharmacy-and-chemistry/#blockid10625">https://www.kingston.ac.uk/faculties/science-engineering-and-computing/about/schools/life-sciences-pharmacy-and-chemistry/#blockid10625</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                University of Rome “Foro Italico”- Department of Movement, Human and Health Sciences
            </div>
            <div class="padding-top-16 width-full">
                The Department of “Sport, Human, and Health Sciences” is part of the University of Rome - Foro Italico,
                the only vocational State university in Italy fully devoted to Sport Sciences. The university is a young
                university, founded at the end of last century (1998) and promoted by a Law Reform that nationally
                transformed the specialization schools of Physical Education. The university also is a small
                institution, comprising a total of about 70 academic scholars and about 130 administrative personnel.
                All academic scholars are part of the department of “Sport, Human, and Health Sciences” and they overall
                represent nearly 20 different scientific fields, ranging from bioengineering, sport medicine, or sport
                and physical education to sport psychology, biochemistry, and sport law and administration. Broadly, the
                Department research interests are in the areas of physiology of movement, fitness, health and
                well-being, sport pedagogy, sport psychology and performance, and biomechanics. The department’s
                research track record is nationally quite well recognized, with most of its research scientific
                production and funding being positioned well above national benchmark criteria.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="http://www.uniroma4.it/">http://www.uniroma4.it/</a>
            </div>
        </div>       

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Sheffield Hallam University Department of Psychology, Sociology & Politics
            </div>
            <div class="padding-top-16 width-full">
                Sheffield Hallam University's Department of Psychology, Sociology & Politics (PSP) is focused on
                research on health and well-being, behaviour change, brain and cognition, and social policy, and 45% of
                research at the Department has been recognized as internationally excellent. In February 2018 the PSP
                Department, became part of the UK Government's Behavioural Insights Framework partners, a consortium of
                academic and industrial partners that utilize and translate behavioural science insights to guide
                effective policies for the benefit of the society. Importantly, PSP maintains a strong track record of
                research publications and funded projects (by The European Commission/Erasmus+ Sport, the World
                Anti-Doping Agency, and the International Olympics Committee) on anti-doping and clean sport education.
                It also has a strong record of research projects and publication in positive psychology, and the
                application of positive interventions to improve mental and physical well-being in diverse populations.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://www.shu.ac.uk/about-us/academic-departments/psychology-sociology-and-politics">https://www.shu.ac.uk/about-us/academic-departments/psychology-sociology-and-politics</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Library and Information Centre of the Hungarian Academy of Sciences, Department of Science Policy and
                Scientometrics
            </div>
            <div class="padding-top-16 width-full">
                The Department of Science Policy and Scientometrics within the Library and Information Centre of the
                Hungarian Academy of Sciences serves as a national centre for scientometrics, bibliometrics, research
                evaluation and research monitoring. Similarly to international centres of scientometrics, we are engaged
                both in service and research. The department's primary task is to provide relevant information for the
                decision-making and evaluating units, leading forums, organizations and institutions of the MTA through
                professional analytic service, regular investigations and data service, as well as various national and
                international tasks.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="http://www.mtakszi.iif.hu/eng/index.php">http://www.mtakszi.iif.hu/eng/index.php</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Pro-Sharp Research and Innovation Center
            </div>
            <div class="padding-top-16 width-full">
                Research, development and innovation management requires up-to-date knowledge on current science,
                research directions and trends, timely problems and solutions provided by the scientific community. In
                knowledge-intensive sectors such as the medical and healthcare sector, or infocommunication technologies
                it is vital to support problem-solving, development. The most genuine, comprehensive and trustworthy
                sources of this essential knowledge are the large-scale collections of scientific publications. However,
                the scale and growth rate of international publication output virtually prevents field experts and
                practicing developers to cope with such amounts of scientific information, in terms of either access and
                retrieval of the relevant knowledge base, or processing and synthesising the results. Pro-Sharp Research
                and Innovation Center provides suitable services based on state-of-the-art research methodologies to
                assist the use of the most appropriate and high quality knowledge base underpinning innovation and
                development.
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://prosharp-research.com/">https://prosharp-research.com/</a>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-weight-semibold text-center width-full font-size-20">
                Pro-Sharp Hungary
            </div>
            <div class="padding-top-16 width-full text-center">
                <span class="font-weight-semibold">Website: </span><a
                    href="https://www.pro-sharp.hu/">https://www.pro-sharp.hu/</a>
            </div>
        </div>

    </div>
</section>

<div class="section section-divider hero-image-background"></div>