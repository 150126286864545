import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private trackingID: string;

  constructor() { }

  initialize(trackingID: string): void {
    this.trackingID = trackingID;

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=" + trackingID;

    const initScript = document.createElement("script");
    initScript.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date());";

    document.head.appendChild(script);
    document.head.appendChild(initScript);
  }

  sendPageViewEvent(url: string) {
    (window as any).gtag('event', 'page_view', { 'send_to': this.trackingID, 'page_path': url });
  }
}