<div class="dashboard-card__header">
    <div class="dashboard-card__title">
        <div class="d-flex">
            <span class="flex-grow-1">{{headerTitle}}</span>
            <span>
                <app-cluster-tooltip [content]="headerDescription"></app-cluster-tooltip>
            </span>
        </div>
    </div>
</div>
<div class="dashboard-card__content justify-content-center align-items-center" #dashboardContent>
    <img *ngIf="imageUrl" class="width-max-full" [src]="imageUrl" (error)="onError()" (load)="onLoad()"
        [hidden]="hasError" [alt]="headerTitle">
        
    <section class="research-document" *ngIf="hasError">
        <div class="research-document__title">Something went wrong.</div>
    </section>
</div>
<div class="dashboard-card__footer">
    <a class="cursor-pointer underline" (click)="onViewImage()">View larger image</a>
</div>