import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/internal/Subscription';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollaborationGraphStoreService {
  private subscriptions: Subscription = new Subscription();
  public graph: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.getGraphData();
  }

  private getGraphData() {
    this.subscriptions.add(this.http.get("assets/data/coro.json")
      .subscribe((links) => {
        if (!links)
          this.graph.next(null);

        this.graph.next(this.createGraphDataByLinks(links));
      }, (error) => {
        console.log(error);
      })
    );
  }


  private createGraphDataByLinks(links) {
    let nodes = [];

    links.forEach(link => {
      let nodeBySource = nodes.find(node => node.id === link.source);
      if (!nodeBySource) {
        nodes.push({
          id: link.source,
          name: link.source,
          count: link.Weight
        });
      }
      else {
        nodeBySource.count += link.Weight;
      }

      let nodeByTarget = nodes.find(node => node.id === link.target);
      if(!nodeByTarget) {
        nodes.push({
          id: link.target,
          name: link.target,
          count: link.Weight
        });
      }
      else {
        nodeByTarget.count += link.Weight;
      }

    });

    return {
      nodes: nodes,
      links: links
    };
  }
}
