import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PagedResultOfPublicationReferenceModel } from 'src/app/apis/models';
import { ClusterStoreService } from 'src/app/core/cluster-store.service';
import { ClusterType } from 'src/app/models/cluster-type.enum';

@Component({
  selector: 'app-cluster-references',
  templateUrl: './cluster-references.component.html', 
})
export class ClusterReferencesComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private _clusterId: number;
  @Input()
  get clusterId(): number { return this._clusterId; }
  set clusterId(value: number) { this._clusterId = value; this.changeClusterId(); }
  public pagedResult: PagedResultOfPublicationReferenceModel = null;
  public hasError: boolean = false;
  public noResult: boolean = false;
  @ViewChild("dashboardContent") dashboardContent: ElementRef;

  constructor(private clusterStore: ClusterStoreService) { }
  ngOnInit(): void { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private changeClusterId() {
    this.getPublicationReferences();
  }

  onPageChange(pageNumber: number) {
    this.getPublicationReferences(pageNumber);
  }

  private getPublicationReferences(pageNumber: number = 1, pageSize: number = 10): void {
    this.hasError = false;
    this.noResult = false;

    if (!this.clusterId)
      return;

    this.subscriptions.add(this.clusterStore.getClustersData(
        ClusterType.CoreReferences,
        this.clusterId.toString(),
        pageNumber,
        pageSize
      ).pipe(first())
      .subscribe((result: PagedResultOfPublicationReferenceModel) => {
        this.pagedResult = result;
        this.noResult = !this.pagedResult || !this.pagedResult.results || this.pagedResult.results.length === 0;
        this.scrollContentToTop();
      }, (error) => {
        this.pagedResult = null;
        this.hasError = true;

        console.log(error);
      }));
  }

  private scrollContentToTop() {
    this.dashboardContent.nativeElement.scrollTo(0, 0);
  }
}
