<div class="dashboard-card__header">
    <div class="dashboard-card__title">
        <div class="d-flex">
            <span class="flex-grow-1">Core references</span>
            <span>
                <ng-template #tooltipContent>
                    <span class="font-italic">The collection of and navigation within the most important <span
                            class="font-weight-bold">papers cited</span> in the cluster.</span> One of the greatest
                    benefits of the bibliometric methodology we employed is that it can be used to reveal the historical
                    background and „genealogy” of literature. This is highly important in covering research relevant to
                    the present situation, but rooted in earlier time periods. This is exactly what the list of the most
                    relevant cited papers presents for each cluster, that is, the literature dominantly cited by the
                    papers in the cluster. These cited publications are also presented with links to the full text
                    publications, where available.
                    <br>
                    <span class="font-weight-bold">Frequency score:</span> The number of citations to the publication
                    within the cluster
                </ng-template>
                <app-cluster-tooltip [content]="tooltipContent"></app-cluster-tooltip>
            </span>
        </div>
    </div>
</div>
<div class="dashboard-card__content" #dashboardContent>
    <section class="research-document" *ngIf="noResult">
        <div class="research-document__title">No core references result.</div>
    </section>
    <section class="research-document" *ngIf="hasError">
        <div class="research-document__title">Something went wrong. Please reload the page.</div>
    </section>
    <section class="research-document" *ngFor="let publication of pagedResult?.results">
        <div class="research-document__title">{{publication.title}}</div>
        <div class="research-document__description">
            <ul>
                <li>{{publication.authors}}</li>
                <li>{{publication.source}}</li>
            </ul>
        </div>
        <div class="research-document__link d-flex justify-content-between">
            <div>
                <span *ngIf="publication.digitalObjectPoint" class="padding-right-8">
                    DOI: <a [href]="'http://dx.doi.org/' + publication.digitalObjectPoint"
                        target="_blank" rel="noopener noreferrer">{{publication.digitalObjectPoint}}</a>
                </span>
            </div>
            <div class="text-align-right">
                <span *ngIf="publication.frequence" class="padding-left-8">
                    Frequency score:
                    <span class="font-weight-regular">{{publication.frequence}}</span>
                </span>
            </div>
        </div>
    </section>
</div>
<div class="dashboard-card__footer" *ngIf="pagedResult">
    <ngb-pagination [(page)]="pagedResult.currentPage" [pageSize]="pagedResult.pageSize"
        [collectionSize]="pagedResult.rowCount" (pageChange)="onPageChange($event)" [ellipses]="true" [maxSize]="12">
    </ngb-pagination>
</div>