<div class="dashboard">
    <section class="dashboard__sidebar">
        <div class="dashboard__sidebar__content width-full">
            <div class="dashboard__sidebar__title">
                Clusters
            </div>
            <app-cluster-list></app-cluster-list>
        </div>
    </section>

    <section class="dashboard__main">
        <div class="dashboard__main__content width-full">
            <div class="dashboard__main__title">
                {{selectedCluster?.name}}
            </div>

            <div class="dashboard__grid">
                <div class="dashboard__grid__item dashboard__grid__item--diagram">
                    <app-cluster-image
                        [imageUrl]="selectedCluster?.id ? 'assets/images/cluster-images/' + selectedCluster.id + '.png' : null"
                        headerTitle="The chronological development of the cluster"
                        headerDescription="It is very informative to see how research directions have been developing over time, in terms of their size. Therefore, a comparative time series is also reported for each cluster, where the annual volume of publications is indicated in a bar plot for the entire time period."
                        class="dashboard-card">
                    </app-cluster-image>
                </div>

                <div *ngIf="clusterId" class="dashboard__grid__item dashboard__grid__item--dendogram">
                    <app-topical-structure-of-the-cluster
                        [imageUrl]="selectedCluster?.id ? 'assets/images/cluster-images/' + selectedCluster.id + '_dend.jpg' : null"
                        [clusterId]="selectedCluster?.id" class="dashboard-card">
                    </app-topical-structure-of-the-cluster>
                </div>

                <div *ngIf="clusterId" class="dashboard__grid__item dashboard__grid__item--authors">
                    <app-authors
                        [imageUrl]="selectedCluster?.id ? 'assets/images/cluster-images/' + selectedCluster.id + '_authors.png' : null"
                        [clusterId]="selectedCluster?.id" class="dashboard-card">
                    </app-authors>
                </div>

                <div class="dashboard__grid__item dashboard__grid__item--core-documents">
                    <app-cluster-core-document [clusterId]="selectedCluster?.id" class="dashboard-card">
                    </app-cluster-core-document>
                </div>

                <div class="dashboard__grid__item dashboard__grid__item--references">
                    <app-cluster-references [clusterId]="selectedCluster?.id" class="dashboard-card">
                    </app-cluster-references>
                </div>

                <div class="dashboard__grid__item dashboard__grid__item--publications">
                    <app-cluster-publications [clusterId]="selectedCluster?.id" class="dashboard-card">
                    </app-cluster-publications>
                </div>
            </div>
        </div>
    </section>
</div>