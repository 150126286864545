import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClusterStoreService } from './cluster-store.service';
import { AspectsStoreService } from './aspects-store.service';
import { DesignsStoreService } from './designs-store.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { WindowEventManager } from './window-event-manager.service';
import { ClusterGraphStoreService } from './cluster-graph-store.service';
import { CountryMapStoreService } from './country-map-store.service';
import { CollaborationGraphStoreService } from './collaboration-graph-store.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports:[
    CommonModule
  ],
  providers: [
    ClusterStoreService,
    ClusterGraphStoreService,
    AspectsStoreService,
    DesignsStoreService,
    CountryMapStoreService,
    CollaborationGraphStoreService,
    GoogleAnalyticsService,
    WindowEventManager
  ]
})
export class CoreModule { }
