import { Injectable, OnDestroy } from '@angular/core';
import { PublicationService } from '../apis/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AspectModel, AspectQueryParams } from '../apis/models';

@Injectable({
  providedIn: 'root'
})
export class AspectsStoreService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public list: BehaviorSubject<AspectModel[]> = new BehaviorSubject([]);
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private publicationService: PublicationService) { }

  public getList(params: AspectQueryParams) {
    this.loading.next(true);
    this.subscriptions.add(this.publicationService.PublicationGetAspectsBySelectedDesigns(params)
      .pipe(first())
      .subscribe((result: AspectModel[]) => {
        this.loading.next(false);
        this.list.next(result);
      }, (error) => {
        this.loading.next(false);
        this.list.next([]);

        // TODO Error handling
        console.log(error);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
