import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mta-logo',
  templateUrl: './mta-logo.component.html',  
})
export class MtaLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
