<div class="dashboard-card__header">
  <div class="dashboard-card__title">
    <div class="d-flex">
      <span class="flex-grow-1">Network of key authors in the cluster, based on joint publications</span>
      <span>
        <ng-template #tooltipContent>
          The network shows co-authorship relations, where nodes represent authors and edges (links) between nodes
          represent the volume of their joint publications. The width of the edges is proportional to the number of
          publications produced in collaboration. The size of nodes is indicative of the betweenness centrality of the
          authors.
        </ng-template>
        <app-cluster-tooltip [content]="tooltipContent"></app-cluster-tooltip>
      </span>
    </div>
  </div>
</div>

<div class="illustration-with-selector" #dashboardContent>
  <section class="illustration-with-selector__illustration-container">
    <img *ngIf="imageUrl" [src]="imageUrl" alt="Authors diagram" style="width: 100%;">
  </section>

  <section class="illustration-with-selector__selector-container">
    <div class="dashboard-card__filters">
      <div class="width-100-percent">
        <label class="margin-bottom-8 font-weight-semibold">
          Author
        </label>
        <ng-select [items]="authorNamesByCluster" [(ngModel)]="selectedAuthorName" [hideSelected]="true"
          (change)="onAuthorSelected()" placeholder="Select an author...">
        </ng-select>
      </div>
    </div>

    <div *ngIf="nothingWasSelected" class="dashboard-card__content">
      <div>Select an author to see more information.</div>
    </div>

    <div *ngIf="selectedAuthorData" class="dashboard-card__content">
      <p class="font-size-36 margin-bottom-0">
        {{selectedAuthorData.degreeCentrality}}
      </p>
      <p class="font-weight-semibold margin-bottom-0">
        Degree Centrality
      </p>
      <p>
        The number of connections of a node. A node with a higher number of degree centrality has more connections with
        the other nodes (authors) in the graph.
      </p>

      <p class="font-size-36 margin-bottom-0">
        {{selectedAuthorData.betweennessCentralityNormalized}}
      </p>
      <p class="font-weight-semibold margin-bottom-0">
        Betweenness Centrality (normalized)
      </p>
      <p>
        A measure of node's (author's) capacity to connect more-or-less separated subgroups (that are otherwise
        internally densely connected) within the graph. It ranges from 0 to 1 (being normalized to the size of the
        network). The closer the value to 1, the more important knowledge broker the author is to the community within
        the cluster.
      </p>

    </div>
  </section>
</div>