<div class="dashboard-card__header">
  <div class="dashboard-card__title">
    <div class="d-flex">
      <span class="flex-grow-1">{{ headerTitle }}</span>
      <span>
        <ng-template #tooltipContent>
          For each cluster, a dendogram is presented to reveal the most important topics and their interrelations within
          the cluster. This is the result of a network analysis on the co-occurence of key concepts within the research
          direction. Terms of the same color belong together, forming a coherent thematic unit. The nodes are also
          colored (from red to blue as a heatmap scale), showing how frequently the term appears within the cluster.
          In the drop-down list showing the publications pertaining to user-selected keywords only a subset of relevant
          papers (publications with a so-called DOI number being assigned to them) is shown.
        </ng-template>
        <app-cluster-tooltip [content]="tooltipContent"></app-cluster-tooltip>
      </span>
    </div>
  </div>
</div>


<div class="illustration-with-selector" #dashboardContent>
  <section
    class="illustration-with-selector__illustration-container flex flex-direction-column justify-content-between">
    <img *ngIf="imageUrl" [src]="imageUrl" alt="The topical structure of the cluster" style="width: 100%;">
    <div class="dashboard-card__footer">
      <a class="cursor-pointer underline" (click)="onViewImage()">View larger image</a>
    </div>
  </section>

  <section class="illustration-with-selector__selector-container flex flex-direction-column justify-content-between">
    <!-- Select country -->

    <div>

      <div class="dashboard-card__filters">
        <div class="width-100-percent">
          <label class="margin-bottom-8 font-weight-semibold">
            Keyword
          </label>
          <ng-select [items]="keywordsByCluster" [(ngModel)]="selectedKeyword" [hideSelected]="true"
            (change)="onKeywordSelected()" placeholder="Select a keyword...">
          </ng-select>
        </div>
      </div>

      <!-- Content -->
      <div class="dashboard-card__content" #dashboardContent>
        <section *ngIf="noResult">
          <div>There are no publications for the selected keyword.</div>
        </section>
        <section *ngIf="nothingWasSelected">
          <div>Select a keyword to see the relevant publications.</div>
        </section>
        <section class="research-document" *ngFor="let publication of pagedResult?.results">
          <div class="research-document__title">{{publication.title}}</div>
          <div class="research-document__description">
            <ul>
              <li>{{publication.authors}}</li>
              <li>{{publication.source}}</li>
            </ul>
          </div>
          <div class="research-document__link d-flex justify-content-between">
            <div>
              <span *ngIf="publication.digitalObjectPoint" class="padding-right-8">
                DOI: <a [href]="'http://dx.doi.org/' + publication.digitalObjectPoint" target="_blank"
                  rel="noopener noreferrer">{{publication.digitalObjectPoint}}</a>
              </span>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Pagination -->
    <div class="dashboard-card__footer" *ngIf="pagedResult">
      <ngb-pagination [(page)]="pagedResult.currentPage" [pageSize]="pagedResult.pageSize"
        [collectionSize]="pagedResult.rowCount" (pageChange)="onPageChange($event)" [ellipses]="true" [maxSize]="12">
      </ngb-pagination>
    </div>

  </section>
</div>