import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClusterStoreService } from '../core/cluster-store.service';
import { ClusterModel } from '../apis/models';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-clusters',
  templateUrl: './clusters.component.html',
})
export class ClustersComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  clusters: ClusterModel[] = [];
  selectedCluster: ClusterModel = null;
  clusterId: number | null = null;

  constructor(private clusterStore: ClusterStoreService, private activatedRoute: ActivatedRoute) {
    this.clusters = this.clusterStore.clusters;
    this.subscribeRouteChange();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscribeRouteChange() {
    this.subscriptions.add(this.activatedRoute.params.subscribe((params) => {
      this.clusterId = parseInt(params.clusterId, 10);

      // TODO Error handling
      if (!this.clusterId || this.clusters?.length === 0) {
        return;
      }

      this.selectedCluster = this.clusters.find((cluster) => {
        return cluster.id === this.clusterId;
      });
    }));
  }
}
