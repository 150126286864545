import { Injectable } from '@angular/core';
import { ClusterModel, PagedResultOfPublicationModel, PublicationModel } from '../apis/models';
import { ClusterType } from '../models/cluster-type.enum';
import { Observable, of } from 'rxjs';
import * as coreData from '../../assets/data/core-data.json';
import * as coreReferences from '../../assets/data/core-references.json';
import * as corePublications from '../../assets/data/core-publications.json';

@Injectable({
  providedIn: 'root'
})
export class ClusterStoreService {
  private readonly clustersData: ClusterModel[] = [
    {
      id: 1,
      name: 'Doping: psychology, personal values and morality'
    },
    {
      id: 2,
      name: 'Moral disengagement, anti- and prosocial behavior in sport'
    },
    {
      id: 3,
      name: 'Athlete\'s identity, commitment and mental toughness'
    },
    {
      id: 5,
      name: 'Fair play, integrity, morality and philosophy of sport'
    },
    {
      id: 7,
      name: 'Motivation and achievement goal effects on sportpersonship'
    },
  ];



  constructor() {
  }

  get clusters() {
    return this.clustersData;
  }

  getClustersData(clusterType: ClusterType, clusterID: string, pageNumber: number, pageSize: number): Observable<any> {
    let json;

    switch (clusterType) {
      case ClusterType.CoreData:
        json = JSON.stringify(coreData.coreData);
        break;
      case ClusterType.CorePublications:
        json = JSON.stringify(corePublications.corePublications);
        break;
      case ClusterType.CoreReferences:
        json = JSON.stringify(coreReferences.coreReferences);
        break;

      default:
        break;
    }

    const parsed = JSON.parse(json);
    let pagedResultOfPublication: PagedResultOfPublicationModel = {
      results: undefined,
      rowCount: undefined,
      currentPage: pageNumber,
      pageSize: pageSize
    };
    const results = parsed.filter(x => x.clusterID === clusterID);
    const pagedResults: [PublicationModel] = results.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

    pagedResultOfPublication.results = pagedResults;
    pagedResultOfPublication.rowCount = results.length;

    return of(pagedResultOfPublication);
  }
}
