<div class="dashboard-card__header">
    <div class="dashboard-card__title">
        <div class="d-flex">
            <span class="flex-grow-1">Core documents</span>
            <span>
                <ng-template #tooltipContent>
                    <span class="font-italic">The collection of and navigation within <span
                            class="font-weight-bold">core papers.</span></span> Using an advanced bibliometric method,
                    we obtained the so-called core papers for each research direction, the most representative
                    publications in the cluster. The publications can be accessed through this list.
                    <br>
                    <span class="font-weight-bold">Relevance score:</span> The extent to which the publication is
                    characteristic of the main theme of the cluster. The value ranges between 0, as not relevant at all,
                    to 1, as the most relevant document for the theme.
                </ng-template>
                <app-cluster-tooltip [content]="tooltipContent"></app-cluster-tooltip>
            </span>
        </div>
    </div>
</div>
<div class="dashboard-card__content" #dashboardContent>
    <section class="research-document" *ngIf="noResult">
        <div class="research-document__title">No core documents result.</div>
    </section>
    <section class="research-document" *ngIf="hasError">
        <div class="research-document__title">Something went wrong. Please reload the page.</div>
    </section>
    <section class="research-document" *ngFor="let publication of pagedResult?.results">
        <div class="research-document__title">{{publication.title}}</div>
        <div class="research-document__description">
            <ul>
                <li>{{publication.authors}}</li>
                <li>{{publication.source}}</li>
            </ul>
        </div>
        <div class="research-document__link d-flex justify-content-between">
            <div>
                <span *ngIf="publication.digitalObjectPoint" class="padding-right-8">
                    DOI: <a [href]="'http://dx.doi.org/' + publication.digitalObjectPoint"
                        target="_blank" rel="noopener noreferrer">{{publication.digitalObjectPoint}}</a>
                </span>
            </div>
            <div class="text-align-right">
                <span *ngIf="publication.relevance" class="padding-left-8">
                    Relevance score:
                    <span class="font-weight-regular">{{publication.relevance | number:'1.0-2'}}</span>
                </span>
            </div>
        </div>
    </section>
</div>
<div class="dashboard-card__footer" *ngIf="pagedResult">
    <ngb-pagination [(page)]="pagedResult.currentPage" [pageSize]="pagedResult.pageSize"
        [collectionSize]="pagedResult.rowCount" (pageChange)="onPageChange($event)" [ellipses]="true" [maxSize]="12">
    </ngb-pagination>
</div>