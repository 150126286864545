<div class="logo-prosharp">
    <a href="https://prosharp-research.com/" target="_blank" rel="noopener noreferrer">
        <svg width="276px" height="40px" viewBox="0 0 276 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group-6" fill-rule="nonzero">
                    <polygon id="Path" fill="#F6946B" points="-3.13082893e-13 34.1505376 6.68817204 31.9139785 6.68817204 27.0322581 -3.13082893e-13 29.2688172"></polygon>
                    <polygon id="Path" fill="#F6946B" points="-3.13082893e-13 21.9569892 6.68817204 19.7204301 6.68817204 14.8387097 -3.13082893e-13 17.0967742"></polygon>
                    <polygon id="Path" fill="#F6946B" points="27.4193548 24.9892473 34.1290323 22.7311828 34.1290323 17.8709677 27.4193548 20.1075269"></polygon>
                    <polygon id="Path" fill="#F6946B" points="27.4193548 7.91397849 27.4193548 12.7956989 34.1290323 10.5591398 34.1290323 5.67741935"></polygon>
                    <polygon id="Path" fill="#F6946B" points="13.3978495 4.06451613 8.51612903 5.69892473 8.51612903 12.4086022 13.3978495 10.7741935"></polygon>
                    <polygon id="Path" fill="#F6946B" points="20.7096774 8.32258065 25.5913978 6.68817204 25.5913978 7.99360578e-14 20.7096774 1.61290323"></polygon>
                    <polygon id="Path" fill="#F6946B" points="20.7096774 35.7634409 25.5913978 34.1290323 25.5913978 27.4193548 20.7096774 29.0537634"></polygon>
                    <polygon id="Path" fill="#A52A57" points="8.51612903 14.2365591 8.51612903 39.827957 13.3978495 38.2150538 13.3978495 17.483871 20.7096774 15.0322581 20.7096774 22.344086 15.2258065 24.1935484 15.2258065 29.0537634 20.7096774 27.2258065 25.5913978 25.5913978 25.5913978 13.3978495 25.5913978 8.51612903 13.3978495 12.6021505"></polygon>
                    <path d="M48.9032258,26.7311828 L48.9032258,34.1290323 L45.0967742,34.1290323 L45.0967742,14.0430108 L48.9032258,14.0430108 L48.9032258,15.8709677 C50.0215054,14.4516129 51.5913978,13.6774194 53.3763441,13.6774194 C57.0967742,13.6774194 59.7849462,16.4516129 59.7849462,21.2903226 C59.7849462,26.1290323 57.0967742,28.9462366 53.3763441,28.9462366 C51.655914,28.9462366 50.1075269,28.2365591 48.9032258,26.7311828 Z M55.8709677,21.311828 C55.8709677,18.8172043 54.3655914,17.0967742 52.1935484,17.0967742 C50.9677419,17.0967742 49.5913978,17.7849462 48.9247312,18.7526882 L48.9247312,23.8709677 C49.5483871,24.7956989 50.9677419,25.5483871 52.1935484,25.5483871 C54.3655914,25.5483871 55.8709677,23.827957 55.8709677,21.311828 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M62.0645161,28.5806452 L62.0645161,14.0430108 L65.8709677,14.0430108 L65.8709677,16 C66.9247312,14.7311828 68.688172,13.6774194 70.4946237,13.6774194 L70.4946237,17.4193548 C70.2150538,17.3548387 69.8924731,17.3333333 69.4408602,17.3333333 C68.172043,17.3333333 66.4946237,18.0645161 65.8709677,18.9892473 L65.8709677,28.6021505 L62.0645161,28.6021505 L62.0645161,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M71.0322581,21.311828 C71.0322581,17.1827957 73.9139785,13.6989247 78.688172,13.6989247 C83.483871,13.6989247 86.3655914,17.1827957 86.3655914,21.311828 C86.3655914,25.4408602 83.483871,28.9677419 78.688172,28.9677419 C73.9139785,28.9462366 71.0322581,25.4193548 71.0322581,21.311828 Z M82.3870968,21.311828 C82.3870968,19.0537634 81.0752688,17.0967742 78.6666667,17.0967742 C76.3010753,17.0967742 74.9677419,19.0537634 74.9677419,21.311828 C74.9677419,23.5913978 76.2795699,25.5483871 78.6666667,25.5483871 C81.0752688,25.5483871 82.3870968,23.5913978 82.3870968,21.311828 Z" id="Shape" fill="#A52A57"></path>
                    <polygon id="Path" fill="#A52A57" points="88.5806452 22.9247312 88.5806452 19.6774194 95.7849462 19.6774194 95.7849462 22.9247312"></polygon>
                    <path d="M97.2258065,26.688172 L98.8817204,23.9139785 C99.9569892,24.9462366 102.086022,25.9354839 103.892473,25.9354839 C105.548387,25.9354839 106.322581,25.311828 106.322581,24.4086022 C106.322581,22.0215054 97.7419355,23.9784946 97.7419355,18.2580645 C97.7419355,15.827957 99.8494624,13.6774194 103.677419,13.6774194 C106.107527,13.6774194 108.064516,14.516129 109.483871,15.655914 L107.956989,18.3870968 C107.096774,17.483871 105.44086,16.7096774 103.677419,16.7096774 C102.301075,16.7096774 101.397849,17.311828 101.397849,18.1290323 C101.397849,20.2580645 110,18.4516129 110,24.3225806 C110,27.0107527 107.72043,28.9677419 103.698925,28.9677419 C101.182796,28.9462366 98.7526882,28.1075269 97.2258065,26.688172 Z" id="Path" fill="#A52A57"></path>
                    <path d="M122.086022,28.5806452 L122.086022,19.7419355 C122.086022,17.7204301 121.032258,17.0967742 119.419355,17.0967742 C117.913978,17.0967742 116.752688,17.9354839 116.129032,18.7741935 L116.129032,28.5806452 L112.301075,28.5806452 L112.301075,8.51612903 L116.129032,8.51612903 L116.129032,15.9139785 C117.053763,14.8387097 118.817204,13.6774194 121.204301,13.6774194 C124.387097,13.6774194 125.913978,15.4193548 125.913978,18.2580645 L125.913978,28.5806452 L122.086022,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M137.741935,28.5806452 L137.741935,27.0537634 C136.752688,28.2580645 135.053763,28.9462366 133.16129,28.9462366 C130.860215,28.9462366 128.150538,27.3763441 128.150538,24.1290323 C128.150538,20.688172 130.83871,19.4408602 133.16129,19.4408602 C135.11828,19.4408602 136.795699,20.0645161 137.741935,21.2258065 L137.741935,19.3978495 C137.741935,17.9139785 136.473118,16.9677419 134.55914,16.9677419 C133.032258,16.9677419 131.591398,17.5698925 130.387097,18.688172 L128.88172,16 C130.645161,14.4086022 132.924731,13.7204301 135.204301,13.7204301 C138.537634,13.7204301 141.569892,15.0537634 141.569892,19.2258065 L141.569892,28.6236559 L137.741935,28.6236559 L137.741935,28.5806452 Z M137.741935,25.0967742 L137.741935,23.2903226 C137.11828,22.4516129 135.913978,22 134.688172,22 C133.182796,22 131.956989,22.8172043 131.956989,24.1935484 C131.956989,25.5698925 133.182796,26.3655914 134.688172,26.3655914 C135.913978,26.3655914 137.096774,25.9354839 137.741935,25.0967742 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M144.623656,28.5806452 L144.623656,14.0430108 L148.430108,14.0430108 L148.430108,16 C149.483871,14.7311828 151.247312,13.6774194 153.053763,13.6774194 L153.053763,17.4193548 C152.774194,17.3548387 152.451613,17.3333333 152,17.3333333 C150.731183,17.3333333 149.053763,18.0645161 148.430108,18.9892473 L148.430108,28.6021505 L144.623656,28.6021505 L144.623656,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M158.537634,26.7311828 L158.537634,34.1290323 L154.731183,34.1290323 L154.731183,14.0430108 L158.537634,14.0430108 L158.537634,15.8709677 C159.655914,14.4516129 161.225806,13.6774194 163.010753,13.6774194 C166.731183,13.6774194 169.419355,16.4516129 169.419355,21.2903226 C169.419355,26.1290323 166.731183,28.9462366 163.010753,28.9462366 C161.290323,28.9462366 159.741935,28.2365591 158.537634,26.7311828 Z M165.483871,21.311828 C165.483871,18.8172043 163.978495,17.0967742 161.806452,17.0967742 C160.580645,17.0967742 159.204301,17.7849462 158.537634,18.7526882 L158.537634,23.8709677 C159.16129,24.7956989 160.580645,25.5483871 161.806452,25.5483871 C164,25.5483871 165.483871,23.827957 165.483871,21.311828 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M185.72043,16.5376344 L184.150538,13.6774194 L182.903226,13.6774194 L182.903226,16.5376344 L181.182796,16.5376344 L181.182796,8.49462366 L184.946237,8.49462366 C186.623656,8.49462366 187.655914,9.59139785 187.655914,11.0752688 C187.655914,12.4946237 186.752688,13.2473118 185.892473,13.4623656 L187.72043,16.516129 L185.72043,16.516129 L185.72043,16.5376344 Z M185.892473,11.0752688 C185.892473,10.4086022 185.376344,10 184.709677,10 L182.903226,10 L182.903226,12.172043 L184.709677,12.172043 C185.376344,12.172043 185.892473,11.7634409 185.892473,11.0752688 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M188.258065,13.6129032 C188.258065,11.9354839 189.505376,10.5591398 191.268817,10.5591398 C193.010753,10.5591398 194.193548,11.8709677 194.193548,13.7419355 L194.193548,14.1075269 L189.870968,14.1075269 C189.978495,14.8172043 190.55914,15.4193548 191.548387,15.4193548 C192.043011,15.4193548 192.709677,15.2043011 193.096774,14.8602151 L193.784946,15.8709677 C193.204301,16.4086022 192.301075,16.688172 191.376344,16.688172 C189.591398,16.6666667 188.258065,15.483871 188.258065,13.6129032 Z M191.268817,11.8064516 C190.322581,11.8064516 189.913978,12.4731183 189.849462,13.0537634 L192.709677,13.0537634 C192.666667,12.4946237 192.27957,11.8064516 191.268817,11.8064516 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M194.602151,15.7634409 L195.268817,14.6451613 C195.698925,15.0537634 196.55914,15.4623656 197.290323,15.4623656 C197.956989,15.4623656 198.258065,15.2043011 198.258065,14.8387097 C198.258065,13.8924731 194.817204,14.6666667 194.817204,12.3870968 C194.817204,11.4193548 195.655914,10.5591398 197.204301,10.5591398 C198.172043,10.5591398 198.967742,10.9032258 199.548387,11.3548387 L198.924731,12.4301075 C198.580645,12.0645161 197.913978,11.7634409 197.204301,11.7634409 C196.645161,11.7634409 196.27957,12 196.27957,12.3225806 C196.27957,13.1827957 199.741935,12.4516129 199.741935,14.7956989 C199.741935,15.8709677 198.817204,16.6451613 197.204301,16.6451613 C196.193548,16.6666667 195.204301,16.344086 194.602151,15.7634409 Z" id="Path" fill="#A52A57"></path>
                    <path d="M200.322581,13.6129032 C200.322581,11.9354839 201.569892,10.5591398 203.333333,10.5591398 C205.075269,10.5591398 206.258065,11.8709677 206.258065,13.7419355 L206.258065,14.1075269 L201.935484,14.1075269 C202.043011,14.8172043 202.623656,15.4193548 203.612903,15.4193548 C204.107527,15.4193548 204.774194,15.2043011 205.16129,14.8602151 L205.849462,15.8709677 C205.268817,16.4086022 204.365591,16.688172 203.44086,16.688172 C201.655914,16.6666667 200.322581,15.483871 200.322581,13.6129032 Z M203.333333,11.8064516 C202.387097,11.8064516 201.978495,12.4731183 201.913978,13.0537634 L204.774194,13.0537634 C204.731183,12.4946237 204.344086,11.8064516 203.333333,11.8064516 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M210.688172,16.5376344 L210.688172,15.9139785 C210.301075,16.3870968 209.612903,16.6666667 208.83871,16.6666667 C207.913978,16.6666667 206.817204,16.0430108 206.817204,14.7311828 C206.817204,13.3548387 207.892473,12.8602151 208.83871,12.8602151 C209.612903,12.8602151 210.301075,13.1182796 210.688172,13.5698925 L210.688172,12.8387097 C210.688172,12.2580645 210.172043,11.8709677 209.419355,11.8709677 C208.795699,11.8709677 208.236559,12.1075269 207.741935,12.5591398 L207.139785,11.483871 C207.849462,10.8387097 208.774194,10.5591398 209.677419,10.5591398 C211.010753,10.5591398 212.236559,11.0967742 212.236559,12.7741935 L212.236559,16.5376344 L210.688172,16.5376344 Z M210.688172,15.1397849 L210.688172,14.4086022 C210.430108,14.0645161 209.956989,13.8924731 209.462366,13.8924731 C208.860215,13.8924731 208.365591,14.2150538 208.365591,14.7741935 C208.365591,15.3333333 208.860215,15.6344086 209.462366,15.6344086 C209.956989,15.6344086 210.430108,15.4623656 210.688172,15.1397849 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M213.548387,16.5376344 L213.548387,10.7096774 L215.075269,10.7096774 L215.075269,11.483871 C215.505376,10.9677419 216.215054,10.5591398 216.924731,10.5591398 L216.924731,12.0430108 C216.817204,12.0215054 216.688172,12 216.494624,12 C215.978495,12 215.311828,12.2795699 215.053763,12.6666667 L215.053763,16.516129 L213.548387,16.516129 L213.548387,16.5376344 Z" id="Path" fill="#A52A57"></path>
                    <path d="M217.290323,13.6129032 C217.290323,11.827957 218.602151,10.5591398 220.365591,10.5591398 C221.548387,10.5591398 222.27957,11.0752688 222.666667,11.6129032 L221.655914,12.5591398 C221.376344,12.1505376 220.967742,11.9354839 220.430108,11.9354839 C219.505376,11.9354839 218.860215,12.6021505 218.860215,13.6129032 C218.860215,14.6236559 219.505376,15.311828 220.430108,15.311828 C220.967742,15.311828 221.376344,15.0752688 221.655914,14.6666667 L222.666667,15.6129032 C222.27957,16.1505376 221.548387,16.6666667 220.365591,16.6666667 C218.602151,16.6666667 217.290323,15.3978495 217.290323,13.6129032 Z" id="Path" fill="#A52A57"></path>
                    <path d="M227.397849,16.5376344 L227.397849,12.9892473 C227.397849,12.172043 226.967742,11.9354839 226.322581,11.9354839 C225.72043,11.9354839 225.247312,12.2795699 224.989247,12.6021505 L224.989247,16.5376344 L223.44086,16.5376344 L223.44086,8.49462366 L224.989247,8.49462366 L224.989247,11.4623656 C225.354839,11.0322581 226.064516,10.5806452 227.032258,10.5806452 C228.301075,10.5806452 228.924731,11.2688172 228.924731,12.4086022 L228.924731,16.5376344 L227.397849,16.5376344 Z" id="Path" fill="#A52A57"></path>
                    <path d="M237.032258,16.5376344 L237.032258,15.9139785 C236.645161,16.3870968 235.956989,16.6666667 235.182796,16.6666667 C234.258065,16.6666667 233.16129,16.0430108 233.16129,14.7311828 C233.16129,13.3548387 234.236559,12.8602151 235.182796,12.8602151 C235.956989,12.8602151 236.645161,13.1182796 237.032258,13.5698925 L237.032258,12.8387097 C237.032258,12.2580645 236.516129,11.8709677 235.763441,11.8709677 C235.139785,11.8709677 234.580645,12.1075269 234.086022,12.5591398 L233.483871,11.483871 C234.193548,10.8387097 235.11828,10.5591398 236.021505,10.5591398 C237.354839,10.5591398 238.580645,11.0967742 238.580645,12.7741935 L238.580645,16.5376344 L237.032258,16.5376344 Z M237.032258,15.1397849 L237.032258,14.4086022 C236.774194,14.0645161 236.301075,13.8924731 235.806452,13.8924731 C235.204301,13.8924731 234.709677,14.2150538 234.709677,14.7741935 C234.709677,15.3333333 235.204301,15.6344086 235.806452,15.6344086 C236.301075,15.6344086 236.774194,15.4623656 237.032258,15.1397849 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M243.806452,16.5376344 L243.806452,13.0107527 C243.806452,12.1935484 243.376344,11.9354839 242.731183,11.9354839 C242.129032,11.9354839 241.677419,12.2795699 241.397849,12.6021505 L241.397849,16.5376344 L239.870968,16.5376344 L239.870968,10.7096774 L241.397849,10.7096774 L241.397849,11.4623656 C241.763441,11.0322581 242.494624,10.5806452 243.44086,10.5806452 C244.731183,10.5806452 245.354839,11.311828 245.354839,12.4301075 L245.354839,16.5376344 L243.806452,16.5376344 L243.806452,16.5376344 Z" id="Path" fill="#A52A57"></path>
                    <path d="M250.688172,16.5376344 L250.688172,15.7849462 C250.236559,16.3655914 249.612903,16.6666667 248.903226,16.6666667 C247.44086,16.6666667 246.322581,15.5483871 246.322581,13.6129032 C246.322581,11.7204301 247.419355,10.5591398 248.903226,10.5591398 C249.591398,10.5591398 250.236559,10.8387097 250.688172,11.4408602 L250.688172,8.49462366 L252.236559,8.49462366 L252.236559,16.5376344 L250.688172,16.5376344 Z M250.688172,14.6451613 L250.688172,12.5806452 C250.430108,12.1935484 249.870968,11.9139785 249.376344,11.9139785 C248.494624,11.9139785 247.892473,12.6021505 247.892473,13.5913978 C247.892473,14.6021505 248.494624,15.2903226 249.376344,15.2903226 C249.892473,15.311828 250.451613,15.0322581 250.688172,14.6451613 Z" id="Shape" fill="#A52A57"></path>
                    <polygon id="Path" fill="#A52A57" points="181.182796 28.5806452 181.182796 20.5376344 182.903226 20.5376344 182.903226 28.5806452"></polygon>
                    <path d="M188.236559,28.5806452 L188.236559,25.0537634 C188.236559,24.2365591 187.806452,23.9784946 187.16129,23.9784946 C186.55914,23.9784946 186.107527,24.3225806 185.827957,24.6451613 L185.827957,28.5806452 L184.301075,28.5806452 L184.301075,22.7526882 L185.827957,22.7526882 L185.827957,23.5053763 C186.193548,23.0752688 186.924731,22.6236559 187.870968,22.6236559 C189.16129,22.6236559 189.784946,23.3548387 189.784946,24.4731183 L189.784946,28.5806452 L188.236559,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M195.010753,28.5806452 L195.010753,25.0537634 C195.010753,24.2365591 194.580645,23.9784946 193.935484,23.9784946 C193.333333,23.9784946 192.88172,24.3225806 192.602151,24.6451613 L192.602151,28.5806452 L191.075269,28.5806452 L191.075269,22.7526882 L192.602151,22.7526882 L192.602151,23.5053763 C192.967742,23.0752688 193.698925,22.6236559 194.645161,22.6236559 C195.935484,22.6236559 196.55914,23.3548387 196.55914,24.4731183 L196.55914,28.5806452 L195.010753,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M197.505376,25.655914 C197.505376,24 198.666667,22.6021505 200.580645,22.6021505 C202.516129,22.6021505 203.655914,24 203.655914,25.655914 C203.655914,27.311828 202.494624,28.7096774 200.580645,28.7096774 C198.666667,28.7311828 197.505376,27.311828 197.505376,25.655914 Z M202.086022,25.655914 C202.086022,24.7526882 201.548387,23.9784946 200.602151,23.9784946 C199.655914,23.9784946 199.11828,24.7526882 199.11828,25.655914 C199.11828,26.5806452 199.655914,27.3548387 200.602151,27.3548387 C201.548387,27.3548387 202.086022,26.5806452 202.086022,25.655914 Z" id="Shape" fill="#A52A57"></path>
                    <polygon id="Path" fill="#A52A57" points="206 28.5806452 203.655914 22.7526882 205.290323 22.7526882 206.817204 26.7956989 208.344086 22.7526882 209.978495 22.7526882 207.634409 28.5806452"></polygon>
                    <path d="M213.978495,28.5806452 L213.978495,27.9569892 C213.591398,28.4301075 212.903226,28.7096774 212.129032,28.7096774 C211.204301,28.7096774 210.107527,28.0860215 210.107527,26.7741935 C210.107527,25.3978495 211.182796,24.9032258 212.129032,24.9032258 C212.903226,24.9032258 213.591398,25.1612903 213.978495,25.6129032 L213.978495,24.8817204 C213.978495,24.3010753 213.462366,23.9139785 212.709677,23.9139785 C212.086022,23.9139785 211.526882,24.1505376 211.032258,24.6021505 L210.430108,23.5268817 C211.139785,22.8817204 212.064516,22.6021505 212.967742,22.6021505 C214.301075,22.6021505 215.526882,23.1397849 215.526882,24.8172043 L215.526882,28.5806452 L213.978495,28.5806452 Z M213.978495,27.1827957 L213.978495,26.4516129 C213.72043,26.1075269 213.247312,25.9354839 212.752688,25.9354839 C212.150538,25.9354839 211.655914,26.2580645 211.655914,26.8172043 C211.655914,27.3763441 212.150538,27.6774194 212.752688,27.6774194 C213.247312,27.6774194 213.72043,27.5268817 213.978495,27.1827957 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M217.139785,27.1182796 L217.139785,24.0860215 L216.172043,24.0860215 L216.172043,22.7526882 L217.139785,22.7526882 L217.139785,21.1612903 L218.666667,21.1612903 L218.666667,22.7526882 L219.849462,22.7526882 L219.849462,24.0860215 L218.666667,24.0860215 L218.666667,26.7096774 C218.666667,27.0752688 218.860215,27.3548387 219.204301,27.3548387 C219.44086,27.3548387 219.655914,27.2688172 219.741935,27.1827957 L220.064516,28.344086 C219.827957,28.5591398 219.419355,28.7096774 218.795699,28.7096774 C217.698925,28.7311828 217.139785,28.172043 217.139785,27.1182796 Z" id="Path" fill="#A52A57"></path>
                    <path d="M220.666667,21.2043011 C220.666667,20.688172 221.075269,20.3010753 221.569892,20.3010753 C222.086022,20.3010753 222.494624,20.688172 222.494624,21.2043011 C222.494624,21.7204301 222.086022,22.1290323 221.569892,22.1290323 C221.075269,22.1290323 220.666667,21.7204301 220.666667,21.2043011 Z M220.817204,28.5806452 L220.817204,22.7526882 L222.344086,22.7526882 L222.344086,28.5806452 L220.817204,28.5806452 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M223.268817,25.655914 C223.268817,24 224.430108,22.6021505 226.344086,22.6021505 C228.27957,22.6021505 229.419355,24 229.419355,25.655914 C229.419355,27.311828 228.258065,28.7096774 226.344086,28.7096774 C224.430108,28.7311828 223.268817,27.311828 223.268817,25.655914 Z M227.827957,25.655914 C227.827957,24.7526882 227.290323,23.9784946 226.344086,23.9784946 C225.397849,23.9784946 224.860215,24.7526882 224.860215,25.655914 C224.860215,26.5806452 225.397849,27.3548387 226.344086,27.3548387 C227.311828,27.3548387 227.827957,26.5806452 227.827957,25.655914 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M234.322581,28.5806452 L234.322581,25.0537634 C234.322581,24.2365591 233.892473,23.9784946 233.247312,23.9784946 C232.645161,23.9784946 232.193548,24.3225806 231.913978,24.6451613 L231.913978,28.5806452 L230.387097,28.5806452 L230.387097,22.7526882 L231.913978,22.7526882 L231.913978,23.5053763 C232.27957,23.0752688 233.010753,22.6236559 233.956989,22.6236559 C235.247312,22.6236559 235.870968,23.3548387 235.870968,24.4731183 L235.870968,28.5806452 L234.322581,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M240.129032,24.5591398 C240.129032,22.0860215 242,20.4086022 244.408602,20.4086022 C246.172043,20.4086022 247.182796,21.3548387 247.763441,22.3655914 L246.301075,23.0967742 C245.956989,22.4516129 245.247312,21.9354839 244.430108,21.9354839 C242.967742,21.9354839 241.892473,23.0537634 241.892473,24.5806452 C241.892473,26.1075269 242.946237,27.2258065 244.430108,27.2258065 C245.247312,27.2258065 245.978495,26.7096774 246.301075,26.0645161 L247.763441,26.7741935 C247.204301,27.7849462 246.172043,28.7526882 244.408602,28.7526882 C242,28.7311828 240.129032,27.0322581 240.129032,24.5591398 Z" id="Path" fill="#A52A57"></path>
                    <path d="M248.27957,25.655914 C248.27957,23.9784946 249.526882,22.6021505 251.290323,22.6021505 C253.032258,22.6021505 254.215054,23.9139785 254.215054,25.7849462 L254.215054,26.1505376 L249.892473,26.1505376 C250,26.8602151 250.580645,27.4623656 251.569892,27.4623656 C252.064516,27.4623656 252.731183,27.2473118 253.11828,26.9032258 L253.806452,27.9139785 C253.225806,28.4516129 252.322581,28.7311828 251.397849,28.7311828 C249.612903,28.7311828 248.27957,27.5268817 248.27957,25.655914 Z M251.290323,23.8709677 C250.344086,23.8709677 249.935484,24.5376344 249.870968,25.1182796 L252.731183,25.1182796 C252.688172,24.5591398 252.301075,23.8709677 251.290323,23.8709677 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M259.075269,28.5806452 L259.075269,25.0537634 C259.075269,24.2365591 258.645161,23.9784946 258,23.9784946 C257.397849,23.9784946 256.946237,24.3225806 256.666667,24.6451613 L256.666667,28.5806452 L255.139785,28.5806452 L255.139785,22.7526882 L256.666667,22.7526882 L256.666667,23.5053763 C257.032258,23.0752688 257.763441,22.6236559 258.709677,22.6236559 C260,22.6236559 260.623656,23.3548387 260.623656,24.4731183 L260.623656,28.5806452 L259.075269,28.5806452 Z" id="Path" fill="#A52A57"></path>
                    <path d="M262.258065,27.1182796 L262.258065,24.0860215 L261.290323,24.0860215 L261.290323,22.7526882 L262.258065,22.7526882 L262.258065,21.1612903 L263.784946,21.1612903 L263.784946,22.7526882 L264.967742,22.7526882 L264.967742,24.0860215 L263.784946,24.0860215 L263.784946,26.7096774 C263.784946,27.0752688 263.978495,27.3548387 264.322581,27.3548387 C264.55914,27.3548387 264.774194,27.2688172 264.860215,27.1827957 L265.182796,28.344086 C264.946237,28.5591398 264.537634,28.7096774 263.913978,28.7096774 C262.817204,28.7311828 262.258065,28.172043 262.258065,27.1182796 Z" id="Path" fill="#A52A57"></path>
                    <path d="M265.462366,25.655914 C265.462366,23.9784946 266.709677,22.6021505 268.473118,22.6021505 C270.215054,22.6021505 271.397849,23.9139785 271.397849,25.7849462 L271.397849,26.1505376 L267.075269,26.1505376 C267.182796,26.8602151 267.763441,27.4623656 268.752688,27.4623656 C269.247312,27.4623656 269.913978,27.2473118 270.301075,26.9032258 L270.989247,27.9139785 C270.408602,28.4516129 269.505376,28.7311828 268.580645,28.7311828 C266.795699,28.7311828 265.462366,27.5268817 265.462366,25.655914 Z M268.473118,23.8709677 C267.526882,23.8709677 267.11828,24.5376344 267.053763,25.1182796 L269.913978,25.1182796 C269.870968,24.5591398 269.483871,23.8709677 268.473118,23.8709677 Z" id="Shape" fill="#A52A57"></path>
                    <path d="M272.322581,28.5806452 L272.322581,22.7526882 L273.849462,22.7526882 L273.849462,23.5268817 C274.27957,23.0107527 274.989247,22.6021505 275.698925,22.6021505 L275.698925,24.0860215 C275.591398,24.0645161 275.462366,24.0430108 275.268817,24.0430108 C274.752688,24.0430108 274.086022,24.3225806 273.827957,24.7096774 L273.827957,28.5591398 L272.322581,28.5591398 L272.322581,28.5806452 Z" id="Path" fill="#A52A57"></path>
                </g>
            </g>
        </svg>
    </a>
</div>
