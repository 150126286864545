import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-country-network',
  templateUrl: './country-network.component.html',
  styleUrls: ['./country-network.component.scss']
})
export class CountryNetworkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
