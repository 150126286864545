import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClusterImageViewerModalComponent } from './cluster-image-viewer-modal/cluster-image-viewer-modal.component';

@Component({
  selector: 'app-cluster-image',
  templateUrl: './cluster-image.component.html',
})
export class ClusterImageComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() headerTitle: string;
  @Input() headerDescription: string;
  @ViewChild('dashboardContent') dashboardContent: ElementRef;
  hasError = false;

  constructor(private modalService: NgbModal) { }
  ngOnInit(): void { }

  onError() {
    this.hasError = true;
  }

  onLoad() {
    this.hasError = false;
    this.scrollContentToTop();
  }

  onViewImage() {
    const modal = this.modalService.open(ClusterImageViewerModalComponent, { size: 'xl' });
    modal.componentInstance.imageUrl = this.imageUrl;
    modal.componentInstance.title = this.headerTitle;
  }

  private scrollContentToTop() {
    this.dashboardContent.nativeElement.scrollTo(0, 0);
  }
}
