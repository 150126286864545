<!-- Mapping -->
<section class="hero hero--large hero-image-background">
    <div class="hero__section-1">
        <div class="hero__title" data-aos="fade-in" data-aos-delay="100" data-aos-easing="ease-out-cubic">
            Bibliometric mapping of the discourse on values in sport
        </div>
    </div>
    <div class="hero__section-2 padding-top-0">
        <div class="section__content width-max-768">
            <div class="section__content__content padding-bottom-0" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <div class="font-size-20 text-center">
                    "Values in sport" is a theme of dual nature: on one hand, it is interpreted in the sport science
                    community in a specific manner, as being related to the concepts of integrity and clean sport. On
                    the other hand, it is a vaguely defined notion with broad disciplinary background, the latter
                    involving psychology, philosophy and many other fields. However, seeking for codification of the
                    content and professional foundations of this concept requires filling the gap between the narrow and
                    the vague interpretation. This codification is unavoidable for policy makers, managers and other
                    actors in the international arena of sport to support their organization's activities. This portal is
                    the outcome of a research project to undertake a large-scale mapping of the rapidly growing
                    scientific literature associated with the discourse on values in sport research.
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Clusters -->
<section class="section">
    <div class="section__content width-max-1200">
        <img src="assets/images/main-graph-cluster-overview.png" alt="Country network" width="100%" height="100%">
    </div>
</section>
<div class="section section-divider hero-image-background"></div>

<!-- About the project -->
<section class="section">
    <div class="section__content width-max-768">
        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">About the project</span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                Our main aim is to facilitate discovery and synthesis by various informative analyses of the scientific
                knowledge base that can be linked to the discourse on values in sport, in the broadest possible sense.
                We use, primarily but not exclusively, bibliometric mapping methods to reveal how the literature is
                being organized, providing intuitive and effortless options to navigate through the most relevant
                knowledge claims. The data source currently used for the research are the Web of Science databases.
            </div>
        </div>

        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    Is this a list or collection of most recent publications on values in sport?
                </span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                This is not the primary aim of the project. There is now a great variety of exquisite collections
                covering recent publications related to the discourse on values in sport. The main goal of the proposed
                research is to explore and uncover the scholarly discourse on “values in sport”, addressing the thematic
                and disciplinary complexities involved in the topic. The detailed objectives are (1) to identify the
                relevant corpus, including latent contributions to the topic as well, (2) to explore and map the
                internal thematic and institutional organization of the literature, and (3) to extract conceptual
                relationships (a concept map) that underlie the view on how sport value notions are being structured and
                connected.
            </div>
        </div>

        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    Which methods we use in uncovering the structure of this research
                </span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                Uncovering the organization of the literature is a matter of the study of how documents are being
                related, given their topics, methods, authors, etc. Bibliometric methods are highly suited to uncover
                the large-scale patterns of document relatedness, a methodological framework often referred to as
                science mapping. For the systematic exploration of the “values in sport” discourse at many levels of the
                publication output we apply state-of-the art methods from bibliometric science mapping and network
                analysis. We do reconstructions of the “values in sport” literature as a bibliometric network of
                research papers, based on their references (that is, one document citing the other). In the present
                version of the project, we apply the so-called „bibliographic coupling” to reveal clusters of related
                research. At the same time, we undertake an extensive exercise of coding the most relevant set of
                papers, hence categorizing them on their most informative characteristics such as (1) what aspect of the
                “values in sport” is being addressed, and (2) how is the thematic and intellectual structure of this
                research field evolving over time.
            </div>
        </div>

        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    What is the scope and coverage of the project in terms of research subjects?
                </span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                As with the timeframe, our project concerns itself with a broad conceptualization of the values in
                sport-related literature. It is essential to our method to include as much of the related research as
                possible, where „related” means every publication that bears a relevant link to values in sport. This
                means a huge variety of research fields (which we call „Aspects” in the project) such as sport
                philosophy, sport ethics, sport policy, sport sociology, sport education, sport psychology – just to
                mention a few. That is why various topics appear in our mapping that are closely connected with the values in sport topic.
            </div>
        </div>

        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    Visualization/interpretation of the results
                </span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                Our aim is to contribute to the discourse on “values in sport” knowledge base via uncovering how this
                research literature is being organized. We focus on research trends, directions, topics, and their
                interrelations through building a map of publications based on their various linkages, and utilizing
                this map in various informative analyses of this literature. Science mapping results are rendered into
                an on-line interactive knowledge map with various functionalities, such as (1) quick access to the
                organization of the knowledge base (2) effective map-based navigation in the publication set and access
                to relevant publications, (3) showcasing the most important contexts and knowledge claims.
            </div>
        </div>

        <div class="section__content__title">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    What is available?
                </span>
            </div>
        </div>

        <div class="section__content__content padding-bottom-0" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                <p>
                    The site is organized as follows:
                </p>
                <ol>
                    <li>
                        The main map of “values in sport” research. As a starting point, an overall map of the covered
                        literature provides the means of navigation within “values in sport” research. This is a network
                        of about 1000 source publications (n = 920) directly associated with this theme (with an
                        additional set of 200 top references discerned from source publications). The network represents
                        the proximity of publications based on their shared references. The map reveals the main
                        research directions as coherent clusters within the network. Each cluster is labelled after the
                        research direction it covers, and also functions as an interface to access the details of the
                        cluster.
                    </li>
                    <li>
                        The research directions (clusters) of “values in sport” research. For each research direction,
                        that is, cluster of publications visible on the main map, a detailed „dashboard” is available,
                        that both summarizes the characteristics of the research direction and provides an organized
                        access to different facets of the literature comprising the cluster. In particular
                    </li>
                    <ul>
                        <li>
                            The collection of and navigation within core papers. Using an advanced bibliometric method,
                            we obtained the so-called core papers for each research direction, the most representative
                            publications in the cluster. The publications can be accessed through this list.
                        </li>
                        <li>
                            The collection of and navigation within the most important papers cited in the cluster. One
                            of the greatest benefits of the bibliometric methodology we employed is that it can be used
                            to reveal the historical background and „genealogy” of literature. This is highly important
                            in covering research relevant to the present situation, but rooted in earlier time periods.
                            This is exactly what the list of the most relevant cited papers presents for each cluster,
                            that is, the literature dominantly cited by the papers in the cluster. These cited
                            publications are also presented with links to the full text publications, where available.
                        </li>
                        <li>
                            The topical structure of the cluster. For each cluster, a dendogram is presented to reveal
                            the most important topics and their interrelations within the cluster. This is the result of
                            a network analysis on the co-occurence of key concepts within the research direction. Terms
                            of the same color belong together, forming a coherent thematic unit. The nodes are also
                            colored (from red to blue as a heatmap scale), showing how frequently the term appears
                            within the cluster.
                        </li>
                        <li>
                            The chronological development of the cluster. It is also very informative to see how
                            research directions have been developing over time, in terms of their size. Therefore, a
                            comparative time series is also reported for each cluster, where the annual volume of
                            publications is indicated in a bar plot for the entire time period.
                        </li>
                    </ul>
                </ol>
            </div>
        </div>
    </div>
</section>

<!-- Who is behind this project? -->
<section class="section padding-top-0">
    <div class="section__content align-items-center">
        <div class="section__content__title width-full width-max-768">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">Who is behind this project?</span>
            </div>
        </div>

        <div class="section__content__content width-full width-max-768" data-aos="fade-in"
            data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                This mapping project is a voluntary collaboration involving the following:
            </div>
        </div>

        <div class="partnership-grid">
            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--aristotle"></div>
                <div class="partnership__description">
                    Aristotle University of Thessaloniki Department of Physical Education and Sports Science
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--foro-italico"></div>
                <div class="partnership__description">
                    University of Rome “Foro Italico”- Department of Movement, Human and Health Sciences
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--kant"></div>
                <div class="partnership__description">
                    Immanuel Kant Baltic Federal University
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--kingston"></div>
                <div class="partnership__description">
                    Kingston University Department of Applied and Human Sciences
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--leipzig"></div>
                <div class="partnership__description">
                    Leipzig University Faculty of Sport Science
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="300">
                <div class="partnership__image partnership__image--sheffield"></div>
                <div class="partnership__description">
                    Sheffield Hallam University Department of Psychology, Sociology & Politics
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <div class="partnership__image partnership__image--prosharp-dev"></div>
                <div class="partnership__description">
                    Pro-Sharp Software Development
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="100">
                <div class="partnership__image partnership__image--mta"></div>
                <div class="partnership__description">
                    Library and Information Centre of the Hungarian Academy of Sciences,<br>
                    Department of Science Policy and Scientometrics
                </div>
            </div>

            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-delay="200">
                <div class="partnership__image partnership__image--prosharp-ric"></div>
                <div class="partnership__description">
                    Pro-Sharp Research and Innovation Center
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Funding -->
<section class="section padding-top-0">
    <div class="section__content align-items-center">
        <div class="section__content__title width-full width-max-768">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">Funding</span>
            </div>
        </div>

        <div class="partnership-grid">
            <div class="partnership aos-init aos-animate" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <div class="partnership__image partnership__image--international-olympic-committee"></div>
                <div class="partnership__description">
                    Bibliometric mapping of the discourse on values in sport project is funded by the International
                    Olympic Committee.
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 
<section class="section padding-top-0">
    <div class="section__content align-items-center"> -->

<section class="section">
    <div class="section__content width-max-768">
        <div class="section__content__title width-full width-max-768">
            <div class="section-title" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <span class="section-title__orange-stripe">
                    How to cite this page?
                </span>
            </div>
        </div>

        <div class="section__content__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">
            <div class="font-size-16">
                Petróczi, A., Kiss, A., & Soós, S. (2022). Bibliometric mapping of the discourse on values in sport.
                Retrieved from https://www.valuesinsport.com/home
            </div>
        </div>
    </div>
</section>

<div class="section section-divider hero-image-background"></div>