import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClusterStoreService } from './cluster-store.service';

// Temp
@Injectable()
export class ClusterExistGuard implements CanActivate {

    constructor(private clusterStore: ClusterStoreService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return of(this.clusterStore.clusters)
            .pipe(
                map((clusters) => {
                    if (!clusters || clusters.length === 0)
                        return this.router.parseUrl("/home");


                    if (clusters.some(cluster => { return cluster.id === parseInt(next.params.clusterId); }))
                        return true;

                    return this.router.parseUrl("/home");
                })
            );
    }
}