import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfigModel } from './app-config.model';

@Injectable()
export class AppConfig {
    static settings: IAppConfigModel;

    constructor(private http: HttpClient) {}

    load() {
        const configJsonPath = `assets/config/app-config.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(configJsonPath).toPromise().then((response : IAppConfigModel) => {
               AppConfig.settings = <IAppConfigModel>response;
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${configJsonPath}': ${JSON.stringify(response)}`);
            });
        });
    }
}