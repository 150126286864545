<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center">
    <div>
      <img class="width-max-full" [src]="imageUrl" (error)="onError()" (load)="onLoad()" [hidden]="hasError"
      [alt]="title">
    </div>
    <div class="font-weight-bold" *ngIf="hasError">Something went wrong.</div>
  </div>
</div>