import { Component, OnInit, OnDestroy } from '@angular/core';
import * as AnimationOnPageScroll from 'aos';
import { NgbDropdownConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ApiConfiguration } from './apis/api-configuration';
import { AppConfig } from './configuration/app-config.service';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(private ngbDropdownConfig: NgbDropdownConfig,
    private ngbTooltipConfig: NgbTooltipConfig,
    private ngSelectConfig: NgSelectConfig,
    private apiConfig: ApiConfiguration,
    private googleAnalytics: GoogleAnalyticsService,
    private router: Router) {

    // Bootstrap dropdown config
    this.ngbDropdownConfig.container = "body";

    // Bootstrap tooltip config
    this.ngbTooltipConfig.container = "body";
    this.ngbTooltipConfig.triggers = "click";

    // NgSelect config
    // this.ngSelectConfig.appendTo = 'body';

    // Set Api Url
    this.apiConfig.rootUrl = AppConfig.settings.WebApiConfig.RootUrl;

    // Google analytics config
    if (AppConfig.settings?.GoogleAnalytics?.TrackingId) {
      this.googleAnalytics.initialize(AppConfig.settings.GoogleAnalytics.TrackingId);
      this.subscriptions.add(this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          this.googleAnalytics.sendPageViewEvent(event.urlAfterRedirects);
        }));
    }
  }

  ngOnInit(): void {
    // Animation scroll config
    AnimationOnPageScroll.init({
      offset: 15,
      duration: 250
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
