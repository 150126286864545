<section class="hero hero--large hero-image-background">
    <div class="hero__section-1">
        <div class="hero__title" data-aos="fade-in" data-aos-delay="100" data-aos-easing="ease-out-cubic">
            Country network
        </div>
    </div>
    <div class="hero__section-2 padding-top-0">
        <div class="section__content width-max-768">
            <div class="section__content__content padding-bottom-0" data-aos="fade-in" data-aos-easing="ease-out-cubic">
                <div class="font-size-20 text-center">
                    The visualization demonstrates the research collaboration among countries contributing to the
                    publication corpus. In particular, the network shows co-authorship relations, where nodes represent
                    countries and edges (links) between nodes represent the volume of their joint publications. The
                    width of the edges is proportional to the number of publications produced in collaboration. The size
                    of nodes is characteristic of the number of co-publications. The different countries have been
                    indicated by their corresponding three-letter abbreviation according to the ISO 3166 standard of the
                    International Organization of Standardization (ISO).
                </div>
            </div>
        </div>
    </div>
</section>

<section class="flex flex-direction-column align-items-center">
    <div class="margin-top-16 margin-bottom-8 country-network-image-container">
        <img src="assets/images/country-network.png" alt="Country network" width="100%" height="100%">
    </div>
    <div class="margin-top-8 padding-right-16 margin-bottom-16 padding-left-16 dashboard__grid__item dashboard__grid__item--core-documents">
        <app-publications-by-country class="dashboard-card">
        </app-publications-by-country>
    </div>

</section>
<div class="section section-divider hero-image-background"></div>