import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { WindowEventManager } from 'src/app/core/window-event-manager.service';

@Component({
  selector: 'app-cluster-tooltip',
  templateUrl: './cluster-tooltip.component.html',
  styleUrls: ['./cluster-tooltip.component.scss']
})
export class ClusterTooltipComponent implements OnInit {
  @Input("content") tooltipContent: string | TemplateRef<any>;
  @ViewChild("tooltip") tooltip: NgbTooltip;

  private windowScrollSubscription: Subscription;

  constructor(private windowEventManager: WindowEventManager) { }

  ngOnInit(): void { }

  // Tooltip handling
  onShow() {
    this.windowScrollSubscription = this.windowEventManager.scroll.subscribe((event) => {
      this.tooltip.close();
    });
  }

  onHide() {
    this.windowScrollSubscription.unsubscribe();
  }
}
