import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as authorsData from '../../../assets/data/authors.json';
import { Author } from './author';

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
})
export class AuthorsComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardContent') dashboardContent: ElementRef;
  private clusterId$ = new BehaviorSubject<number>(null);
  private componentDestroyed$: Subject<void> = new Subject<void>();

  @Input() imageUrl: string;
  @Input() private set clusterId(value: number) {
    this.clusterId$.next(value);
  }
  private get clusterId(): number {
    return this.clusterId$.getValue();
  }

  private authorsData: Author[];
  private authorsDataByCluster: Author[];
  authorNamesByCluster: string[];
  selectedAuthorName: string = null;
  selectedAuthorData: Author = null;

  nothingWasSelected = true;

  constructor() {
    this.authorsData = JSON.parse(JSON.stringify(authorsData.authorsData));

    this.clusterId$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.clusterId) {
          this.selectedAuthorName = null;
          this.selectedAuthorData = null;
          this.authorsDataByCluster = this.authorsData.filter(d => d.clusterId === this.clusterId);
          this.authorNamesByCluster = this.authorsDataByCluster.map(d => d.author);
        }
      });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  onAuthorSelected() {
    this.nothingWasSelected = false;

    this.selectedAuthorData = this.authorsDataByCluster.find(x => x.author === this.selectedAuthorName);

    if (this.selectedAuthorName === null) {
      this.nothingWasSelected = true;
    }
  }

}
