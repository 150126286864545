<header>
    <div class="header__content width-max-full transform-none" data-aos="fade-in" data-aos-easing="ease-out-cubic">
        <div class="header__content__section-1">
            <div class="header__content__block">              
                <app-pro-sharp-logo></app-pro-sharp-logo>                
            </div>
            <div class="header__content__block">
                <app-mta-logo></app-mta-logo>
            </div>
        </div>
        <div class="header__content__section-2 nav">
            <div class="navigation__button">
                <input type="checkbox" id="navigation__button__checkbox" tabindex="-1" [(ngModel)]="isOpenMenu">
                <label class="navigation__button__content" for="navigation__button__checkbox">
                    <span class="navigation__button__text navigation__button__text--menu">Menu</span>
                    <span class="navigation__button__text navigation__button__text--close">Close</span>
                    <span class="navigation__button__icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </label>
                <div  class="navigation__menu">
                    <ul class="navigation__menu__list">
                        <li class="navigation__menu__item" routerLinkActive="active">
                            <a class="navigation__menu__link" [routerLink]="'/home'">Home</a>
                        </li>
                        <li class="navigation__menu__item" ngbDropdown placement="bottom-right" container="null" routerLinkActive="active">
                            <a class="navigation__menu__link navigation__menu__link-dropdown" ngbDropdownToggle>
                                Clusters
                            </a>
                            <div ngbDropdownMenu class="cluster-list-dropdown">
                                <a ngbDropdownItem *ngFor="let cluster of clusters"
                                    [ngClass]="'cluster-bullet-' + cluster.id" [routerLink]="['/clusters', cluster.id]"
                                    class="cluster-bullet" (click)="dropdownSelect();">
                                    {{cluster.name}}
                                </a>
                            </div>
                        </li>
                        <li class="navigation__menu__item" routerLinkActive="active">
                            <a class="navigation__menu__link" [routerLink]="'/country-network'">Country network</a>
                        </li>
                        <li class="navigation__menu__item" routerLinkActive="active">
                            <a class="navigation__menu__link" href="https://set.valuesinsport.com/home">SET game</a>                          
                        </li>
                        <li class="navigation__menu__item" routerLinkActive="active">
                            <a class="navigation__menu__link" [routerLink]="'/contact'">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>