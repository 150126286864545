import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClusterGraphStoreService {
  public clusterGraph: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.getClusterGraph();
  }

  private getClusterGraph() {
    this.http.get("assets/data/clusters-map.json")
      .pipe(first())
      .subscribe((result) => {
        this.clusterGraph.next(result);
      });
  }
}
