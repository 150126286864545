import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cluster-image-viewer-modal',
  templateUrl: './cluster-image-viewer-modal.component.html',
})
export class ClusterImageViewerModalComponent implements OnInit {
  public imageUrl: string = null;
  public title: string = null;
  public hasError: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit(): void { }

  onError() {
    this.hasError = true;
  }

  onLoad() {
    this.hasError = false;
  }

  onClose() {
    this.activeModal.close();
  }
}