import { Injectable, OnDestroy } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WindowEventManager implements OnDestroy {
    public scroll: Subject<Event> = new Subject();
    public resize: Subject<Event> = new Subject();
    private scrollEventSubscription: Function;
    private resizeEventSubscription: Function;


    constructor(private eventManager: EventManager) {
        this.scrollEventSubscription = this.eventManager.addGlobalEventListener("window", "scroll", this.scrollEventListener.bind(this));
        this.resizeEventSubscription = this.eventManager.addGlobalEventListener("window", "resize", this.resizeEventListener.bind(this));
    }

    private scrollEventListener(event: Event): void {
        this.scroll.next(event);
    }

    private resizeEventListener(event: Event): void {
        this.resize.next(event);
    }

    ngOnDestroy(): void {
        this.scrollEventSubscription();
        this.resizeEventSubscription();
    }
}