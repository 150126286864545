import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ProSharpLogoComponent } from './shared/pro-sharp-logo/pro-sharp-logo.component';
import { ClustersComponent } from './clusters/clusters.component';
import { ClusterCoreDocumentComponent } from './clusters/cluster-core-document/cluster-core-document.component';
import { ClusterReferencesComponent } from './clusters/cluster-references/cluster-references.component';
import { ClusterPublicationsComponent } from './clusters/cluster-publications/cluster-publications.component';
import { AppConfig } from './configuration/app-config.service';
import { ClusterListComponent } from './shared/cluster-list/cluster-list.component';
import { ClusterImageComponent } from './clusters/cluster-image/cluster-image.component';
import { ClusterImageViewerModalComponent } from './clusters/cluster-image/cluster-image-viewer-modal/cluster-image-viewer-modal.component';
import { MtaLogoComponent } from './shared/mta-logo/mta-logo.component';
import { ClusterTooltipComponent } from './clusters/cluster-tooltip/cluster-tooltip.component';

import { CountryNetworkComponent } from './country-network/country-network.component';
import { D3DendogramComponent } from './shared/d3-dendogram/d3-dendogram.component';
import { PublicationsByCountryComponent } from './country-network/publications-by-country/publications-by-country.component';
import { AuthorsComponent } from './clusters/authors/authors.component';
import { TopicalStructureOfTheClusterComponent } from './clusters/topical-structure-of-the-cluster/topical-structure-of-the-cluster.component';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,  
    ContactComponent,
    ProSharpLogoComponent,
    ClustersComponent,
    ClusterCoreDocumentComponent,
    ClusterReferencesComponent,
    ClusterPublicationsComponent,
    ClusterListComponent,
    ClusterImageComponent,
    ClusterImageViewerModalComponent,
    MtaLogoComponent,
    ClusterTooltipComponent,    
    CountryNetworkComponent,
    D3DendogramComponent,
    PublicationsByCountryComponent,
    AuthorsComponent,
    TopicalStructureOfTheClusterComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    NgbModule,
    NgSelectModule
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
