import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ClustersComponent } from './clusters/clusters.component';
import { ClusterExistGuard } from './core/cluster-exist-guard.service';
import { CountryNetworkComponent } from './country-network/country-network.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'clusters/:clusterId', component: ClustersComponent, pathMatch: "full", canActivate: [ClusterExistGuard] },
  { path: 'country-network', component: CountryNetworkComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [ClusterExistGuard]
})
export class AppRoutingModule { }
