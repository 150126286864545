import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { PagedResultOfPublicationModel } from 'src/app/apis/models';
import { ClusterType } from 'src/app/models/cluster-type.enum';
import { ClusterStoreService } from '../../core/cluster-store.service';

@Component({
  selector: 'app-cluster-core-document',
  templateUrl: './cluster-core-document.component.html',
})
export class ClusterCoreDocumentComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  private _clusterId: number;
  @Input()
  get clusterId(): number { return this._clusterId; }
  set clusterId(value: number) { this._clusterId = value; this.changeClusterId(); }

  public pagedResult: PagedResultOfPublicationModel = null;
  public hasError: boolean = false;
  public noResult: boolean = false;
  @ViewChild("dashboardContent") dashboardContent: ElementRef;


  constructor(private clusterStore: ClusterStoreService) { }

  ngOnInit(): void {    
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Change cluster
  private changeClusterId() {
    this.getCorePublications();
  }

  // Page change
  onPageChange(pageNumber: number) {
    this.getCorePublications(pageNumber);
  }

  private getCorePublications(pageNumber: number = 1, pageSize: number = 10): void {
    this.hasError = false;
    this.noResult = false;

    if (!this.clusterId)
      return;

    this.subscriptions.add(this.clusterStore.getClustersData(
      ClusterType.CoreData,
      this.clusterId.toString(),
      pageNumber,
      pageSize
    ).pipe(first())
      .subscribe((result: PagedResultOfPublicationModel) => {
        this.pagedResult = result;      
        this.noResult = !this.pagedResult || !this.pagedResult.results || this.pagedResult.results.length === 0;
        this.scrollContentToTop();
      }, (error) => {
        this.pagedResult = null;
        this.hasError = true;

        console.log(error);
      }));
  }

  // Scroll top content 
  private scrollContentToTop() {
    this.dashboardContent.nativeElement.scrollTo(0, 0);
  }
}
