<div class="dashboard-card__header">
    <div class="dashboard-card__title">
        <div class="d-flex">
            <span class="flex-grow-1">All publications in the cluster</span>          
        </div>
    </div>
</div>

<div class="dashboard-card__content" #dashboardContent>
    <section class="research-document" *ngIf="noResult">
        <div class="research-document__title">No publications result.</div>
    </section>
    <section class="research-document" *ngIf="hasError">
        <div class="research-document__title">Something went wrong. Please reload the page.</div>
    </section>
    <section class="research-document" *ngFor="let publication of pagedResult?.results">
        <div class="research-document__title">{{publication.title}}</div>
        <div class="research-document__description">
            <ul>
                <li>{{publication.authors}}</li>
                <li>{{publication.source}}</li>
            </ul>
        </div>
        <div class="research-document__link" *ngIf="publication.digitalObjectPoint">
            DOI: <a [href]="'http://dx.doi.org/' + publication.digitalObjectPoint"
                target="_blank" rel="noopener noreferrer">{{publication.digitalObjectPoint}}</a>
        </div>
    </section>
</div>
<div class="dashboard-card__footer" *ngIf="pagedResult">
    <ngb-pagination [(page)]="pagedResult.currentPage" [pageSize]="pagedResult.pageSize"
        [collectionSize]="pagedResult.rowCount" (pageChange)="onPageChange($event)" [ellipses]="true" [maxSize]="12">
    </ngb-pagination>
</div>