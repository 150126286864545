import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as countryNetworkData from '../../../assets/data/country-network-data.json';
import * as corePublications from '../../../assets/data/core-publications.json';
import { PublicationsByCountryPagedResult } from './publications-by-country-paged-result';
import { CorePublications } from 'src/app/models/core-publications';
import { CountryNetworkData } from './country-network-data';
import { Countries } from './countries.enum';

@Component({
  selector: 'app-publications-by-country',
  templateUrl: './publications-by-country.component.html',
})
export class PublicationsByCountryComponent implements OnInit {
  @ViewChild('dashboardContent') dashboardContent: ElementRef;

  pagedResult: PublicationsByCountryPagedResult;
  _countries: string[];
  selectedCountry: Countries = null;
  noResult = false;
  nothingWasSelected = false;

  private countryNetwork: CountryNetworkData[];

  private get countries(): string[] {
    return [...new Set(this.countryNetwork.map((item: CountryNetworkData) => item.country))];
  }

  constructor() {
    this.countryNetwork = JSON.parse(JSON.stringify(countryNetworkData.countryNetworkData));
    this._countries = this.countries;
    this.pagedResult = this.getPublicationsByCountry(1, 10, this.selectedCountry);
  }

  ngOnInit(): void { }

  onPageChange(pageNumber: number) {
    this.pagedResult = this.getPublicationsByCountry(pageNumber, 10, this.selectedCountry, true);
  }

  onCountrySelected() {
    this.pagedResult = this.getPublicationsByCountry(1, 10, this.selectedCountry, true);
  }

  private getPublicationsByCountry(
    pageNumber = 1,
    pageSize = 10,
    country: Countries,
    scrollToTop = false
  ) {
    this.noResult = false;
    this.nothingWasSelected = false;

    if (country === null) {
      this.nothingWasSelected = true;
      return;
    }

    const corePubs: CorePublications[] = JSON.parse(JSON.stringify(corePublications.corePublications));
    const pubIds = this.getPublicationIdsByCountry(country);
    const corePubsFilteredByDOI = corePubs.filter(x => pubIds.includes(x.digitalObjectPoint));
    const pagedResults: CorePublications[] = corePubsFilteredByDOI.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

    const pagedResultOfPublication: PublicationsByCountryPagedResult = {
      results: pagedResults,
      rowCount: corePubsFilteredByDOI.length,
      currentPage: pageNumber,
      pageSize
    };

    this.noResult = !pagedResults || pagedResults.length === 0;

    scrollToTop ? this.scrollContentToTop() : null;

    return pagedResultOfPublication;
  }

  private scrollContentToTop() {
    this.dashboardContent.nativeElement.scrollTo(0, 0);
  }

  private getPublicationIdsByCountry(country: Countries): string[] {
    const result = [...new Set(this.countryNetwork.filter(x => x.country === country).map(x => x.digitalObjectPoint))];
    return result;
  }
}
