/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClusterModel } from '../models/cluster-model';
import { AspectModel } from '../models/aspect-model';
import { AspectQueryParams } from '../models/aspect-query-params';
import { DesignModel } from '../models/design-model';
import { DesignQueryParams } from '../models/design-query-params';
import { PagedResultOfPublicationModel } from '../models/paged-result-of-publication-model';
import { PublicationQueryParams } from '../models/publication-query-params';
import { PagedResultOfPublicationReferenceModel } from '../models/paged-result-of-publication-reference-model';
@Injectable({
  providedIn: 'root',
})
class PublicationService extends __BaseService {
  static readonly PublicationGetClustersPath = '/api/Publication/GetClusters';
  static readonly PublicationGetAspectsBySelectedDesignsPath = '/api/Publication/GetAspectsBySelectedDesigns';
  static readonly PublicationGetDesignsBySelectedAspectsPath = '/api/Publication/GetDesignsBySelectedAspects';
  static readonly PublicationGetCorePublicationsByClusterIdPath = '/api/Publication/GetCorePublicationsByClusterId';
  static readonly PublicationGetPublicationsPath = '/api/Publication';
  static readonly PublicationGetPublicationReferencesPath = '/api/Publication/GetPublicationReferences';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  PublicationGetClustersResponse(): __Observable<__StrictHttpResponse<Array<ClusterModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Publication/GetClusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClusterModel>>;
      })
    );
  }  PublicationGetClusters(): __Observable<Array<ClusterModel>> {
    return this.PublicationGetClustersResponse().pipe(
      __map(_r => _r.body as Array<ClusterModel>)
    );
  }

  /**
   * @param aspectQueryParams undefined
   */
  PublicationGetAspectsBySelectedDesignsResponse(aspectQueryParams: AspectQueryParams): __Observable<__StrictHttpResponse<Array<AspectModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = aspectQueryParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Publication/GetAspectsBySelectedDesigns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AspectModel>>;
      })
    );
  }
  /**
   * @param aspectQueryParams undefined
   */
  PublicationGetAspectsBySelectedDesigns(aspectQueryParams: AspectQueryParams): __Observable<Array<AspectModel>> {
    return this.PublicationGetAspectsBySelectedDesignsResponse(aspectQueryParams).pipe(
      __map(_r => _r.body as Array<AspectModel>)
    );
  }

  /**
   * @param aspectQueryParams undefined
   */
  PublicationGetDesignsBySelectedAspectsResponse(aspectQueryParams: DesignQueryParams): __Observable<__StrictHttpResponse<Array<DesignModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = aspectQueryParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Publication/GetDesignsBySelectedAspects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DesignModel>>;
      })
    );
  }
  /**
   * @param aspectQueryParams undefined
   */
  PublicationGetDesignsBySelectedAspects(aspectQueryParams: DesignQueryParams): __Observable<Array<DesignModel>> {
    return this.PublicationGetDesignsBySelectedAspectsResponse(aspectQueryParams).pipe(
      __map(_r => _r.body as Array<DesignModel>)
    );
  }

  /**
   * @param params The `PublicationService.PublicationGetCorePublicationsByClusterIdParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `clusterId`:
   */
  PublicationGetCorePublicationsByClusterIdResponse(params: PublicationService.PublicationGetCorePublicationsByClusterIdParams): __Observable<__StrictHttpResponse<PagedResultOfPublicationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Publication/GetCorePublicationsByClusterId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPublicationModel>;
      })
    );
  }
  /**
   * @param params The `PublicationService.PublicationGetCorePublicationsByClusterIdParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `clusterId`:
   */
  PublicationGetCorePublicationsByClusterId(params: PublicationService.PublicationGetCorePublicationsByClusterIdParams): __Observable<PagedResultOfPublicationModel> {
    return this.PublicationGetCorePublicationsByClusterIdResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfPublicationModel)
    );
  }

  /**
   * @param publicationQueryParams undefined
   */
  PublicationGetPublicationsResponse(publicationQueryParams: PublicationQueryParams): __Observable<__StrictHttpResponse<PagedResultOfPublicationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = publicationQueryParams;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Publication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPublicationModel>;
      })
    );
  }
  /**
   * @param publicationQueryParams undefined
   */
  PublicationGetPublications(publicationQueryParams: PublicationQueryParams): __Observable<PagedResultOfPublicationModel> {
    return this.PublicationGetPublicationsResponse(publicationQueryParams).pipe(
      __map(_r => _r.body as PagedResultOfPublicationModel)
    );
  }

  /**
   * @param params The `PublicationService.PublicationGetPublicationReferencesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `clusterId`:
   */
  PublicationGetPublicationReferencesResponse(params: PublicationService.PublicationGetPublicationReferencesParams): __Observable<__StrictHttpResponse<PagedResultOfPublicationReferenceModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Publication/GetPublicationReferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PagedResultOfPublicationReferenceModel>;
      })
    );
  }
  /**
   * @param params The `PublicationService.PublicationGetPublicationReferencesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `clusterId`:
   */
  PublicationGetPublicationReferences(params: PublicationService.PublicationGetPublicationReferencesParams): __Observable<PagedResultOfPublicationReferenceModel> {
    return this.PublicationGetPublicationReferencesResponse(params).pipe(
      __map(_r => _r.body as PagedResultOfPublicationReferenceModel)
    );
  }
}

module PublicationService {

  /**
   * Parameters for PublicationGetCorePublicationsByClusterId
   */
  export interface PublicationGetCorePublicationsByClusterIdParams {
    pageSize?: number;
    pageNumber?: number;
    clusterId?: number;
  }

  /**
   * Parameters for PublicationGetPublicationReferences
   */
  export interface PublicationGetPublicationReferencesParams {
    pageSize?: number;
    pageNumber?: number;
    clusterId?: number;
  }
}

export { PublicationService }
