<!-- Header -->
<div class="dashboard-card__header">
  <div class="dashboard-card__title">
    <div class="d-flex">
      <span class="flex-grow-1">Publication output by country</span>
    </div>
  </div>
</div>

<!-- Select country -->
<div class="dashboard-card__filters">
  <div class="width-100-percent">
    <label class="margin-bottom-8 font-weight-semibold">
      Country
    </label>
    <ng-select [items]="_countries" [(ngModel)]="selectedCountry" [hideSelected]="true" (change)="onCountrySelected()"
      placeholder="Select a country...">
    </ng-select>
  </div>
</div>

<!-- Content -->
<div class="dashboard-card__content" #dashboardContent>
  <section *ngIf="noResult">
    <div>There are no publications for the selected country.</div>
  </section>
  <section *ngIf="nothingWasSelected">
    <div>Select a country to see the relevant publications.</div>
  </section>
  <section class="research-document" *ngFor="let publication of pagedResult?.results">
    <div class="research-document__title">{{publication.title}}</div>
    <div class="research-document__description">
      <ul>
        <li>{{publication.authors}}</li>
        <li>{{publication.source}}</li>
      </ul>
    </div>
    <div class="research-document__link d-flex justify-content-between">
      <div>
        <span *ngIf="publication.digitalObjectPoint" class="padding-right-8">
          DOI: <a [href]="'http://dx.doi.org/' + publication.digitalObjectPoint"
            target="_blank" rel="noopener noreferrer">{{publication.digitalObjectPoint}}</a>
        </span>
      </div>
    </div>
  </section>
</div>

<!-- Pagination -->
<div class="dashboard-card__footer" *ngIf="pagedResult">
  <ngb-pagination [(page)]="pagedResult.currentPage" [pageSize]="pagedResult.pageSize"
    [collectionSize]="pagedResult.rowCount" (pageChange)="onPageChange($event)" [ellipses]="true" [maxSize]="12">
  </ngb-pagination>
</div>