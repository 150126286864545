import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ClusterModel } from 'src/app/apis/models';
import { ClusterStoreService } from 'src/app/core/cluster-store.service';

@Component({
  selector: 'app-cluster-list',
  templateUrl: './cluster-list.component.html',  
})
export class ClusterListComponent implements OnInit {
  public clusters: ClusterModel[] = [];
  @Output() clusterMouseEnter = new EventEmitter<ClusterModel>();
  @Output() clusterMouseLeave = new EventEmitter<ClusterModel>();

  constructor(private clusterStore: ClusterStoreService) {
    this.clusters = this.clusterStore.clusters;
  }

  ngOnInit(): void { } 

  // Mouse events
  onMouseEnter(cluster: ClusterModel) {
    this.clusterMouseEnter.next(cluster);
  }

  onMouseLeave(cluster: ClusterModel) {
    this.clusterMouseLeave.next(cluster);
  }
}
