import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as keywordPubData from '../../../assets/data/keyword-pub.json';
import * as corePublications from '../../../assets/data/core-publications.json';
import { CorePublications } from 'src/app/models/core-publications';
import { PublicationsByCountryPagedResult } from 'src/app/country-network/publications-by-country/publications-by-country-paged-result';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClusterImageViewerModalComponent } from '../cluster-image/cluster-image-viewer-modal/cluster-image-viewer-modal.component';
import { KeywordPub } from './keyword-pub';

@Component({
  selector: 'app-topical-structure-of-the-cluster',
  templateUrl: './topical-structure-of-the-cluster.component.html',
})
export class TopicalStructureOfTheClusterComponent implements OnInit, OnDestroy {
  @ViewChild('dashboardContent') dashboardContent: ElementRef;
  private clusterId$ = new BehaviorSubject<number>(null);
  private componentDestroyed$: Subject<void> = new Subject<void>();

  @Input() imageUrl: string;
  @Input() private set clusterId(value: number) {
    this.clusterId$.next(value);
  }
  private get clusterId(): number {
    return this.clusterId$.getValue();
  }

  private keywordPubData: KeywordPub[];
  private keywordPubDataByCluster: KeywordPub[];
  keywordsByCluster: string[];
  selectedKeyword: string = null;
  pagedResult: PublicationsByCountryPagedResult = null;

  noResult = false;
  nothingWasSelected = true;
  headerTitle = 'The topical structure of the cluster';

  constructor(private modalService: NgbModal) {
    this.keywordPubData = JSON.parse(JSON.stringify(keywordPubData.keywordPubData));

    this.clusterId$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {

        if (this.clusterId) {
          this.selectedKeyword = null;
          this.pagedResult = null;
          this.keywordPubDataByCluster = this.keywordPubData.filter(d => d.clusterId === this.clusterId);
          this.keywordsByCluster = [...new Set(this.keywordPubDataByCluster.map(d => d.keyword))];
        }
      });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  onPageChange(pageNumber: number) {
    this.pagedResult = this.getPublicationsByKeyword(pageNumber, 10, this.selectedKeyword, true);
  }

  onKeywordSelected() {
    this.pagedResult = this.getPublicationsByKeyword(1, 10, this.selectedKeyword, true);
  }

  onViewImage() {
    const modal = this.modalService.open(ClusterImageViewerModalComponent, { size: 'xl' });
    modal.componentInstance.imageUrl = this.imageUrl;
    modal.componentInstance.title = this.headerTitle;
  }

  private getPublicationsByKeyword(
    pageNumber = 1,
    pageSize = 10,
    keyword: string,
    scrollToTop = false
  ) {
    this.noResult = false;
    this.nothingWasSelected = false;

    if (keyword === null) {
      this.nothingWasSelected = true;
      return;
    }

    const corePubs: CorePublications[] = JSON.parse(JSON.stringify(corePublications.corePublications));
    const pubIds = this.getPublicationIdsByKeyword(keyword);
    const corePubsFilteredByDOI = corePubs.filter(x => pubIds.includes(x.digitalObjectPoint));
    const pagedResults: CorePublications[] = corePubsFilteredByDOI.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

    const pagedResultOfPublication: PublicationsByCountryPagedResult = {
      results: pagedResults,
      rowCount: corePubsFilteredByDOI.length,
      currentPage: pageNumber,
      pageSize
    };

    this.noResult = !pagedResults || pagedResults.length === 0;

    scrollToTop ? this.scrollContentToTop() : null;

    return pagedResultOfPublication;
  }

  private scrollContentToTop() {
    this.dashboardContent.nativeElement.scrollTo(0, 0);
  }

  private getPublicationIdsByKeyword(keyword: string): string[] {
    const result = [...new Set(this.keywordPubDataByCluster.filter(x => x.keyword === keyword).map(x => x.digitalObjectPoint))];
    return result;
  }
}
