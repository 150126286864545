<footer>
    <div class="footer__content" data-aos="fade-in" data-aos-easing="ease-out-cubic">

        <div class="footer__content__section-1">
            <div class="footer__content__logo">
                <app-pro-sharp-logo></app-pro-sharp-logo>
            </div>
            <div class="footer__content__copyright font-size-14">
                &copy; <span id="year">2022</span> All rights reserved. Pro-Sharp Research and Innovation Center
            </div>
        </div>

        <div class="footer__content__section-2">
            <span class="footer__content__title">Address</span>
            <ul class="footer__content__list footer__content__list--address">
                <li>Pro-Sharp Research and Innovation Center</li>
                <li>Bácskai u. 29/A</li>
                <li>Budapest 1145</li>
                <li>Hungary</li>
                <li class="maps-link"><a class="underline" target="_blank" rel="noopener noreferrer"
                        href="https://goo.gl/maps/XFnVxEEUdZq27tUT7"><span>View on Google Maps</span></a></li>
            </ul>
        </div>

        <div class="footer__content__section-4">
            <span class="footer__content__title">Navigation</span>
            <ul class="footer__content__list footer__content__list--navigation">
                <li>
                    <a class="underline" [routerLink]="'/home'">
                        Home
                    </a>
                </li>
                <li>
                    <a class="underline" [routerLink]="['/clusters', 1]">
                        Clusters
                    </a>
                </li>
                <li >
                    <a class="underline" [routerLink]="'/country-network'">
                        Country network
                    </a>
                </li>
                <li >
                    <a class="underline" href="https://set.valuesinsport.com/home">
                        SET game
                    </a>                          
                </li>
                <li>
                    <a class="underline" [routerLink]="'/contact'">
                        Contact
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>