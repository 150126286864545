import { Component, OnInit } from '@angular/core';
import { ClusterModel } from 'src/app/apis/models';
import { ClusterStoreService } from 'src/app/core/cluster-store.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',  
})
export class HeaderComponent implements OnInit {

  public clusters: ClusterModel[] = [];
  public isOpenMenu: boolean = false; 
  
  constructor(private clusterStore: ClusterStoreService) {
    this.clusters = this.clusterStore.clusters;
  }

  ngOnInit(): void { }

  dropdownSelect() {
    this.isOpenMenu = false;
  }
}
