import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-sharp-logo',
  templateUrl: './pro-sharp-logo.component.html', 
})
export class ProSharpLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
