import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-d3-dendogram',
  templateUrl: './d3-dendogram.component.html',
  styleUrls: ['./d3-dendogram.component.scss']
})
export class D3DendogramComponent implements OnInit, AfterViewInit {
  @ViewChild("svgElement") svgElementRef: ElementRef<SVGElement>;

  private fields = [
    "id",
    "name",
    "tag.analytics.aggname",
    "tag.analytics.jobname",
    "tag.analytics.metric",
    "tag.kubernetes.namespace.creationTimestamp",
    "tag.kubernetes.namespace.name",
    "tag.kubernetes.namespace.phase",
    "tag.kubernetes.namespace.uid",
    "tag.kubernetes.node.ExternalIP",
    "tag.kubernetes.node.Hostname",
    "tag.kubernetes.node.InternalIP",
    "tag.kubernetes.node.creationTimestamp",
    "tag.kubernetes.node.name",
    "tag.kubernetes.node.uid",
    "tag.kubernetes.pod.creationTimestamp",
    "tag.kubernetes.pod.hostIP",
    "tag.kubernetes.pod.label.app",
    "tag.kubernetes.pod.label.chart",
    "tag.kubernetes.pod.label.component",
    "tag.kubernetes.pod.label.controller-revision-hash",
    "tag.kubernetes.pod.label.heritage",
    "tag.kubernetes.pod.label.k8s-app",
    "tag.kubernetes.pod.label.kubernetes.io/cluster-service",
    "tag.kubernetes.pod.label.name",
    "tag.kubernetes.pod.label.pod-template-generation",
    "tag.kubernetes.pod.label.pod-template-hash",
    "tag.kubernetes.pod.label.release",
    "tag.kubernetes.pod.label.tier",
    "tag.kubernetes.pod.name",
    "tag.kubernetes.pod.phase",
    "tag.kubernetes.pod.podIP",
    "tag.kubernetes.pod.uid",
    "tag.kubernetes.service",
    "tag.kubernetes.type",
    "tag.collector",
    "units"
  ];
  private key = "tag.kubernetes.pod.label.release";
  private treeData;
  private margin = { top: 20, right: 0, bottom: 30, left: 70 };
  private width = window.innerWidth - this.margin.left - this.margin.right;
  private height = 700 - this.margin.top - this.margin.bottom;
  private treemap = d3.tree().size([this.height, this.width]);
  private counter = 0;
  private duration = 750;
  private root;
  private svg;
  private tags;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    const _this = this;
    this.init();
    this.treeData = this.buildTree(this.fields);

    // Assigns parent, children, height, depth
    this.root = d3.hierarchy(this.treeData, function (d) { return d.children; });
    this.root.x0 = this.height / 2;
    this.root.y0 = 0;


    // collapse all
    this.collapse(this.root);
    this.expandOnKey(this.key, this.root);
    this.update(this.root);

    // TODO: ezt vissza kell rakni majd, ha frissítést szeretnénk képernyő szélesség változásnál
    // window.addEventListener('resize', _.debounce(function(){
    //   _this.update(_this.root)
    // }, 500));
 
  }

  private init() {
    this.svg = d3.select(this.svgElementRef.nativeElement)
      .attr("id", "svg")
      .attr("width", this.width + this.margin.right + this.margin.left)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      // .attr("preserveAspectRatio", "xMinYMin meet")
      //   .attr("viewBox", `0 0 ${width} ${height}`)
      .append("g")
      .attr("transform", "translate("
        + this.margin.left + "," + this.margin.top + ")");



  }

  private buildTree(fields) {
    let tree = {
      "name": "tags",
      "children": []
    }

    const addNodes = (tag) => {
      for (let i = 0; i < this.root.children.length; i++) {
        if (tag === this.root.children[i].name) {
          this.root = this.root.children[i];
          return;
        }
      }
      this.root.children.push({
        'name': tag,
        children: []
      })
      this.root = this.root.children[this.root.children.length - 1]
    }

    fields.forEach((field) => {
      this.tags = field.split('.');
      this.root = tree;
      this.tags.forEach(addNodes)
    })
    return tree;
  }

  //Expand the tree based on key
  private expandOnKey(key, root) {
    var node = root;
    var tags = key.split(".");
    while (tags.length > 0) {
      var found = false;
      var next = tags[0]
      if (node._children) {
        node._children.forEach((child) => {
          if (child.data.name === next) {
            // expand this node
            node.children = node._children;
            node._children = null;
            node = child;
            found = true;
          }
        })
      }
      if (found) {
        tags.shift()
      } else {
        return;
      }
    }
  }

  // Collapse the node and all it's children
  private collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(x => this.collapse(x));
      d.children = null;
    }
  }

  private getHeight(root) {
    if (!root) {
      return 0
    }
    let expandChildren = [0]
    if (root.children) { // if has expanded children
      expandChildren = root.children.map(ea => this.getHeight(ea))
    }
    const max = expandChildren.reduce((a, b) => Math.max(a, b))
    return 1 + max
  }

  private update(source) {
    const _this = this;
    var currentHeight = this.getHeight(this.root);
    var newWidth = window.innerWidth;
    document.getElementById("svg").style.width = newWidth.toString();
    // Assigns the x and y position for the nodes
    var treeData = this.treemap(this.root);

    // Compute the new tree layout.
    var nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for current-depth.
    nodes.forEach(function (d) { d.y = d.depth * window.innerWidth / (currentHeight + Math.log(currentHeight - 1)) });
    // ****************** Nodes section ***************************
    // create tip for nodes
    // var tip = d3.tip()
    //   .attr('class', 'd3-tip')
    //   .offset([-10, 0])
    //   .html(function(d) {
    //     return getPath(d)
    //   })

    // Update the nodes...
    var node = this.svg.selectAll('g.node')
      .data(nodes, function (d) { return d.id || (d.id = this.counter += 1); });



    // Enter any new modes at the parent's previous position.
    var nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .attr("transform", function (d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on('click', function (x) {click(x, _this)});
    // nodeEnter.call(tip);
    // Add Circle for the nodes
    nodeEnter.append('circle')
      .attr('class', 'node')
      .attr('r', 1e-6)
      .style("fill", function (d) {
        return d._children ? "lightsteelblue" : "#fff";
      })
    // .on('mouseover', tip.show) // add tip
    // .on('mouseout', tip.hide);

    // Add labels for the nodes
    nodeEnter.append('text')
      .attr("dy", ".35em")
      .attr("x", function (d) {
        return d.children || d._children ? -13 : 13;
      })
      .attr("text-anchor", function (d) {
        return d.children || d._children ? "end" : "start";
      })
      .text(function (d) { return d.data.name; });

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);
    // Transition to the proper position for the node
    nodeUpdate.transition()
      .duration(this.duration)
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // Update the node attributes and style
    nodeUpdate.select('circle.node')
      .attr('r', 10)
      .style("fill", function (d) {
        if (getPath(d) === this.key) {
          // clicked leaf node
          return '#F88C36';
        }
        return d._children ? "lightsteelblue" : "#fff";
      })
      .attr('cursor', 'pointer');


    // Remove any exiting nodes
    var nodeExit = node.exit().transition()
      .duration(this.duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle')
      .attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);

    // ****************** links section ***************************

    // Update the links...
    var link = this.svg.selectAll('path.link')
      .data(links, function (d) { return d.id; });

    // Enter any new links at the parent's previous position.
    var linkEnter = link.enter().insert('path', "g")
      .attr("class", "d3-dedogram-link")
      .attr('d', function (d) {
        var o = { x: source.x0, y: source.y0 }
        return diagonal(o, o)
      });

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.transition()
      .duration(this.duration)
      .attr('d', function (d) { return diagonal(d, d.parent) });

    // Remove any exiting links
    var linkExit = link.exit().transition()
      .duration(this.duration)
      .attr('d', function (d) {
        var o = { x: source.x, y: source.y }
        return diagonal(o, o)
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d: any) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d) {

      var path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`

      return path;
    }

    // Toggle children on click.
    function click(d, instance) {
      
      console.log(d)
      //d.children is expanded children
      //d._children is collapes children
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      instance.update(d);
    }
    // return the path from root to this node
    function getPath(d) {
      var path = [];
      while (d.parent !== null) {
        path.unshift(d.data.name)
        d = d.parent
      }
      return path.join('.')
    }
  }



}
