import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryMapStoreService implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public map: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    this.getCountryMap();
   }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getCountryMap() {
    this.subscriptions.add(this.http.get("assets/data/custom-map-110m.geojson.json")
      .subscribe((map) => {
        this.map.next(map);
      }, (error) => {
        console.log(error);
      })
    );
  }
}
